import React, { Component } from "react";
import { Icon, Menu, Dropdown } from "antd";
import { withTranslation } from "react-i18next";
import "./style.scss";
import { langList } from "../../lang";
import i18n, {setLanguage} from '../../i18n'

@withTranslation()
class Languageselect extends Component {
  constructor(props, context) {
    super(props, context);
    const list = langList.map((item, i) => {
      return {
        language: item.name,
        abbreviation: item.key,
      };
    });
    this.state = {
      languagelist: list,
      languageNav: false,
      languageinit: [],
    };
  }

  componentDidMount() {
    let index = this.state.languagelist.findIndex((item) => {
      return item.abbreviation === i18n.locale;
    });

    this.setState({ languageinit: this.state.languagelist[index] });
  }
  componentWillUnmount() {}

  chooseLanguage = ({ key }) => {
    this.setState({ languageinit: this.state.languagelist[key] }, () => {
      setLanguage(this.state.languageinit.abbreviation)
    });
  };
  render() {
    const { languagelist, languageinit } = this.state;
    const menu = (
      <Menu onClick={this.chooseLanguage}>
        {languagelist.map((item, index) => {
          return (
            <Menu.Item key={index} className="headBar_span">
              <span style={this.props.txtHide ? { margin: "0 5px" } : {}}>
                {item.language}
              </span>
            </Menu.Item>
          );
        })}
      </Menu>
    );

    return (
      <Dropdown
        overlay={menu}
        placement="bottomCenter"
        trigger={["hover", "click"]}
      >
        <div
          className={`select-language treeNodeUnselectable`}
          onClick={this.selectLanguage}
          style={this.props.txtHide ? { margin: "0", padding: "0" } : {}}
        >
          <Icon style={{ fontSize: "22px" }} type="global" />
          {this.props.txtHide ? null : (
            <span className="language">{languageinit.language}</span>
          )}
          {this.props.txtHide ? null : <Icon type="down" />}
        </div>
      </Dropdown>
    );
  }
}

export default Languageselect;
