import { observable, action, set, values, remove } from "mobx";
import ws, { wsget, host } from "../../ws";
import config from "../../config";
import { ajaxPromise, changeHash, getCookie } from "../../util/index";
import event from "../../util/event";

const num = 3;

class uploadFiles {
  @observable visible = false;
  @observable files = {};
  @observable dis = true; //上传进度列表显隐标识
  @observable finish = false;
  @observable drawer_visible = false;
  @observable small_dis = false;
  idkey = 1;
  network = 1; //连接状态
  interrupt = false; //全部清除标识
  temporaryArr = []; //连接断开后上传的文件暂存列表

  //上传入口
  @action upFile = (filelist, id, reset, root) => {
    this.visible = true;
    this.dis = true;
    this.small_dis = true;
    this.drawer_visible = true;
    this.interrupt = false;

    for (let value of filelist) {
      const key = this.idkey;
      this.idkey++;
      const file = {
        id: key,
        to: id,
        file: value,
        name: value.name,
        percent: 0,
        wrong: false,
        awite: false,
        delet: true, //删除按钮
        invalid: false,
        unsuccessfully: false,
        toBig: false,
      };
      if (root) {
        file.root = root;
      }
      if (!/image\/\w+/.test(value.type)) {
        file.video = true;
      }
      set(this.files, key, file);
    }

    reset && reset(); //清理文件input的value

    this.eventOn();

    if (ws().isconnected()) {
      this.upEach();
    }
  };

  @action eventOn = () => {
    if (!this.connect) {
      this.connect = event.on("wsinit", (res) => {
        if (res === 2) {
          this.network = 0;
        }
      });
    }

    if (!this.Logined) {
      this.Logined = event.on("isAutoLogin", (res) => {
        if (res) {
          this.network = 1;
          this.ipTxt = host();
          this.WSreconnect = false;
          if (this.temporaryArr.length) {
            this.temporaryArr.forEach((_, i) => {
              const obj = this.temporaryArr.splice(i, 1);
              this.nextUp(obj[0]);
            });
          } else {
            this.upEach();
          }
        }
      });
    }
  };

  //循环上传
  @action upEach = () => {
    if (this.interrupt) {
      return;
    }
    let i = 0,
      newArr = [];
    let arr = values(this.files);
    newArr = arr.filter((el) => !el.used);
    if (newArr.length) {
      for (let index = 0; index < arr.length; index++) {
        if (i >= num) {
          break;
        }
        let item = this.files[arr[index].id];
        if (item.wrong) {
          continue;
        }
        if (item.doing) {
          i++;
        } else {
          item.doing = true;
          this.check_hash(item);
          i++;
        }
      }
    } else {
      //全部上传过了
      if (arr.length === 0 && newArr.length === 0) {
        console.log("Upload finished.");
        this.modalHide();
        event.emit("up_refresh");
      }
    }
  };

  //获取hash,验证hash
  @action check_hash = async (item) => {
    item.used = true;
    await this.inspect(item);
    if (item.toBig || item.typeErr) {
      item.delet = true;
      item.wrong = true;
      this.upEach();
      return;
    }

    try {
      item.awite = true;
      item.delet = false;
      const hash = await changeHash(item, { quality: 0.7 }, 2048, 2048);
      item.hash = hash;
      wsget("file_check_hash", hash.data.hash)
        .then((data) => {
          if (!this.interrupt) {
            if (data.id > 0) {
              //存在，直接添加
              item.percent = 99;
              const filedata = { id: data.id };
              if (data.ext) {
                filedata.ext = data.ext;
              }
              this.nextUp({ data: filedata, item });
            } else if (data.id === 0) {
              //不存在，上传
              this.up_server(item);
            }
          }
        })
        .catch((err) => {
          //请求错误
          console.log("err", err);
          item.doing = false;
          item.used = false;
          if (this.network) {
            this.upEach();
          }
        });
    } catch (error) {
      //无效文件  转hash错误
      item.wrong = true;
      item.invalid = true;
      item.delet = true;
      this.upEach();
    }
  };

  @action up_server = (item) => {
    const url = `${host()}/up/${item.video ? "v" : "p"}/${getCookie("lovtok")}/${item.hash.data.hash}/${item.name}`;
    console.log("[UPLOAD]", url);
    item.task = ajaxPromise(item.hash.data.blob, url, item.id);

    item.task
      .uploadProgress((loaded, total, fileId) => {
        const precent = Math.floor((loaded / total) * 100);
        // this.files[fileId].percent = precent;
        item.percent = precent;
      })
      .then((result) => {
        item.percent = 100;
        const { data } = result;
        const filedata = { id: data.id };
        if (data.ext) {
          filedata.ext = data.ext;
        }
        this.nextUp({ item, data: filedata });
      })
      .catch((error) => {
        //上传失败
        console.log("error:", error);
        if (item.cancel) {
          console.log("取消上传");
          return;
        }

        if (this.network) {
          //重试了3次就不显示重试按钮了
          if (item.try < 3) {
            item.retry = true; //显示重试按钮
          } else {
            item.invalid = true;
          }
          item.wrong = true;
          item.failed = true;
          item.delet = true;
          if (!this.WSreconnect) {
            this.WSreconnect = true;
            event.emit('reconnect', true);
            // this.upEach();
          }
        } else if (!navigator.onLine) {
          item.tryUp += 1;
          if (item.tryUp < 5) {
            //重试了3次就不显示重试按钮了
            if (item.try < 3) {
              item.retry = true; //显示重试按钮
            }
            item.wrong = true;
            item.delet = false; //显示删除按钮
            item.failed = true;
          }
          this.upToSever(item);
        }
      });
  };

  //检查文件大小及格式
  @action inspect = (item) => {
    if (/image\/\w+/.test(item.file.type)) {
      if (item.file.size > config.upImgSize) {
        item.toBig = true;
      }
    } else if (/video\/\w+/.test(item.file.type)) {
      if (item.file.size > config.upVideoSize) {
        item.toBig = true;
      }
    } else {
      item.typeErr = true; //不支持的文件
    }
  };

  //保存
  @action nextUp = ({ data, item }) => {
    if (this.network) {
      if (item.root) {
        //相册上传
        let obj = {
          id: Number(item.to),
          list: [data],
        };
        wsget("album_add_files", obj).then(() => {
          event.emit("albumupload", {
            list: obj.list,
            id: item.to,
          });
        }).catch(wsERR => {
          if (wsERR.state === 126) {
            this.temporaryArr.push({ data, item });
          }
        });
      } else {
        //播放列表上传
        let obj = {
          id: Number(item.to),
          list: [data],
          index: 0,
        };
        wsget("playlist_save_to", obj).then((msg) => {
          event.emit("savefiles", {
            list: obj.list,
            id: item.to,
            data: msg,
          });
        }).catch(wsERR => {
          if (wsERR.state === 126) {
            this.temporaryArr.push({ data, item });
          }
        });
      }
      remove(this.files, item.id);
      this.upEach();
      // setTimeout(() => {
      //   remove(this.files, item.id);
      //   this.upEach();
      // }, 300);
    } else {
      this.temporaryArr.push({ data, item });
    }
  };

  //重试上传
  @action retry = (data) => {
    let item = this.files[data.id];
    if (item) {
      if (item.task) {
        item.cancel = true;
        item.task.cancel();
        item.task = null;
      }
      item.wrong = false;
      item.awite = false;
      item.doing = false;
      item.used = false;
      item.try = item.try += 1;
      item.retry = false;
      item.progress = 0;
    }
    this.upEach();
  };

  //删除上传
  @action delete = (data) => {
    let item = this.files[data.id];
    if (item) {
      if (item.task) {
        item.cancel = true;
        item.task.cancel();
        item.task = null;
      }
      remove(this.files, item.id);
    }
  };

  //清除全部
  @action abandon = () => {
    this.interrupt = true;

    for (let k in this.files) {
      let item = this.files[k];
      if (item.task) {
        // cancel the request, the callback function is optional
        item.cancel = true;
        item.task.cancel();
        item.task = null;
      }
      remove(this.files, item.id);
    }
    this.files = {};
    this.modalHide();
  };

  //缩小
  @action is_dis = () => {
    this.dis = !this.dis;
  };

  //上传进度隐藏
  @action modalHide = () => {
    this.visible = false;
    this.drawer_visible = false;
    this.small_dis = false;
    event.rm(this.connect);
    this.connect = null;
    event.rm(this.Logined);
    this.Logined = null;
  };

  @action showDrawer = () => {
    this.drawer_visible = true;
  };

  @action onClose = () => {
    this.drawer_visible = false;
  };

  @action clearData = () => {
    this.visible = false;
    this.files = {};
    this.dis = true; //上传进度列表显隐标识
    this.finish = false;
    this.drawer_visible = false;
    this.small_dis = false;
    this.idkey = 1;
    this.network = 1; //连接状态
    this.interrupt = false; //全部清除标识
    this.temporaryArr = []; //连接断开后上传的文件暂存列表
  };
}

export default new uploadFiles();
