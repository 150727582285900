import React from 'react'
import { withRouter, Switch, Redirect, Route } from 'react-router-dom'
import LoadableComponent from '../../common/Tool/LoadableComponent'
import PrivateRoute from '../../router/PrivateRoute';
import Abnormal from '../Abnormal/index'

const Home = LoadableComponent(() => import('../Home/index'))
const Clear = LoadableComponent(() => import('../Home/ClearPhoto'))
const Album = LoadableComponent(() => import('../Album/index'))
const Albumdetails = LoadableComponent(() => import('../Albumdetails/index'))
const Playlist = LoadableComponent(() => import('../Playlist/index'))
const Playlistdetails = LoadableComponent(() => import('../Playlistdetails/index'))
const Rahmen = LoadableComponent(() => import('../Frame/inidex'))
const Rahmendetails = LoadableComponent(() => import('../FrameDetails/index'))
const Personal = LoadableComponent(() => import('../../page/Personal/index'))
const Feedback = LoadableComponent(() => import('../Feedback/index'))


@withRouter
class ContentMain extends React.Component {
  render() {
    return (
      <div className='page-content-main'>
        <Switch>
          <PrivateRoute exact path='/home' component={Home} />
          <PrivateRoute exact path='/clear' component={Clear} />

          <PrivateRoute exact path='/album' component={Album} />
          <PrivateRoute exact path='/album/detail/:id' component={Albumdetails} />

          <PrivateRoute exact path='/playlist' component={Playlist} />
          <PrivateRoute path='/playlist/detail/:id' component={Playlistdetails} />

          <PrivateRoute exact path='/frame' component={Rahmen} />
          <PrivateRoute path='/frame/detail/:id' component={Rahmendetails} />

          <PrivateRoute exact path='/personal' component={Personal} />

          <PrivateRoute exact path='/feedback' component={Feedback} />

          <Redirect exact from='/' to='/home' />

          <Route component={Abnormal} />
        </Switch>
      </div>
    )
  }
}

export default ContentMain;
