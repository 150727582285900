import React, { PureComponent } from "react";
import { Icon, Button } from "antd";
import { withTranslation } from "react-i18next";
import "./index.scss";

@withTranslation()
class Sorter extends PureComponent {
  state = {
    inner: false,
  };

  componentDidMount() {
    this.resize();
    window.addEventListener("resize", this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  resize = () => {
    if (window.innerWidth < 920) {
      if (!this.state.inner) {
        this.setState({ inner: true });
      }
    } else {
      if (this.state.inner) {
        this.setState({ inner: false });
      }
    }
  };

  render() {
    const {
      t,
      sortList,
      sortDis,
      sortEldis,
      defaultSort,
      isdes,
      changeIndex,
      className,
    } = this.props;
    return (
      <div className={`sort_inner ${className}`}>
        {this.state.inner ? (
          <Button
            style={{ height: "26px", padding: "0 7px" }}
            onClick={sortEldis}
          >
            <i style={{ fontSize: "16px" }} className="iconfont icon-paixu"></i>
          </Button>
        ) : (
          <span className="sort treeNodeUnselectable" onClick={sortEldis}>
            <i style={{ margin:'0 8px 0 0' }} className="iconfont icon-paixu"></i>
            {t(defaultSort.text)}
            {isdes ? <Icon type="arrow-up" /> : <Icon type="arrow-down" />}
          </span>
        )}
        <div
          className="sort-list"
          style={sortDis ? { display: "block" } : { display: "none" }}
        >
          <ul>
            {sortList.map((item, index) => {
              return (
                <li
                  key={item.id}
                  onClick={() => {
                    changeIndex(index);
                  }}
                >
                  <Icon
                    type="check"
                    className={item.id === defaultSort.id ? "check-icon" : null}
                  />
                  {t(item.text)}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}
export default Sorter;
