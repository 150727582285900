import React from 'react';
import err404 from '../../images/404.png';
import './style.scss'

export default class Abnormal extends React.Component {
    render() {
        return (
            <div className='abnormal404'>
                <img src={err404} alt="" />
            </div>
        )
    }
}