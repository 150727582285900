import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { storage } from "./util";
import dayjs from "dayjs";
import { languageKeys, translations } from './lang/'

const resources = {}
for (var k in translations) {
  resources[k] = { translation: { ...translations[k] } }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export function setLanguage(key) {
  storage.set("language", key);
  i18n.changeLanguage(key)
  i18n.locale = key
  dayjs.locale(translations[key].dayjskey || 'en')
}

function init() {
  const lan = storage.get('language');
  if (lan) {
    translations[lan] ? setLanguage(lan) : initLang();
  } else {
    initLang();
  }
}

function initLang() {
  const lan = navigator.language.toLowerCase().replace('_', '-')
  const langs = [lan, lan.split('-')[0]]
  for (var x = 0; x < langs.length; x++) {
    const lang = langs[x]
    for (var t in translations) {
      const { key, alias = '' } = translations[t]
      const al = alias.split(/\s*,\s*/)
      for (var i = 0; i < al.length; i++) {
        if (al[i].toLowerCase() === lang) {
          setLanguage(key)
          return
        }
      }
    }
  }
  setLanguage(languageKeys[0])
}

init()

export default i18n;
