// 一些全局配置

// TODO: 将一些配置信息统一到这个文件中
export default {
  name: "lovcube", // 项目的名称
  version: "1.1.1", // 版本
  vernum: 21, // 版本数字

  upImgSize:10485760, //10M
  upVideoSize:52428800, //50M

  // 初始服务器地址
  serverList:'dPwQJYez6dVxCmBQJYfGp49VArHxbrVxCQsQ1thREt87EYeGbtWQlQC38I2X7IC37IfX7IWPCkRwZGFSArK91dHQlQF/EIyx1Y+TAQuP1dHQpOFBbthsEKesAPFREGCUCmyR8I8jWmij8q8jWXEQ7a3vC4hTAdiMAQCUCk8kWauBJdexpkbMAQCwC49RbtDXgPDTEkhXCmBQWq2RWPsGWgsKWPsX8GFeptwQJYez6dVxCmBQJrC3p49VArHxbrVxCQsQ1thREt87EYeGbtWQlQC38I2X7IC37IfX7IWPCkRwZGFSArK91dHQlQFKEX2x1Y+TAQuP1dHQpOFBbthsEKesAPFREGCUCmyR8I8jWmij8q8jWXEQ7a3vC4hTAdiMAQCUCk+XWauBJdexpkbMAQCwC49RbtDXgPDTEkhXCmBQWq2RWPsGWgsKWPsX8GFeptwQJYez6dVxCmBQJYf3p49VArHxbrVxCQsQ1thREt87EYeGbtWQlQC38I2X7IC37IfX7IWPCkRwZGFSArK91dHQlQFk6mWx1Y+TAQuP1dHQpOFBbthsEKesAPFREGCUCmyR8I8jWmij8q8jWXEQ7a3vC4hTAdiMAQCUCk+XWGuBJdexpkbMAQCwC49RbtDXgPDTEkhXCmBQWq2RWPsGWgsKWPsX8GFeptwQJYez6dVxCmBQ1k0Gp49VArHxbrVxCQsQ1thREt87EYeGbtWQlQC38I2X7IC37IfX7IWPCkRwZGFSArK91dHQlQF9EYSxAdfupkhTEOCwC49RbtDXgPDTEkhXCmBQWq2RWPsGWgsKWPsX8GFeptwQJYez6dVxCmBQJrCGp49VArHxbrVxCQsQ1thREt87EYeGbtWQlQC38I2X7IC37IfX7IWPCkRwZGFSArK91dHQlQFxAIyx1Y+TAQuP1dHQpOFBbthsEKesAPFREGCUCmyR8I8jWmij8q8jWXEQ7a3vC4hTAdiMAQCUC4uwWQuBJdexpkbMAQCwC49RbtDXgPDTEkhXCmBQWq2RWPsGWgsKWPsX8GFeptwQJYez6dVxCmBQErEGp49VArHxbrVxCQsQ1thREt87EYeGbtWQlQC38I2X7IC37IfX7IWPCkRwZGFSArK91dHQlQFKEXfx1Y+TAQuP1dHQpOFBbthsEKesAPFREGCUCmyR8I8jWmij8q8jWXEQ7+R='
}
