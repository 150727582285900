import React, { Component } from "react";
import { Icon, Row, Col, Button, Avatar } from "antd";
import i18next from "i18next";
import HeaderBar from "../HeaderBar";
import SiderNav from "../SiderNav";
import "./style.scss";
import { host } from "../../ws";
import Languageselect from "../../common/Languageselect";
import config from '../../config'


export default class Header extends Component {
  state = {
    visible: false,
    visibleTop: false,
  };

  menuList_show = () => {
    this.setState({ visible: true });
  };

  onClose = () => {
    this.setState({ visible: false });
  };

  onCloseTop = () => {
    this.setState({ visibleTop: false });
  };

  render() {
    const { buyUrl, user, title } = this.props;

    return (
      <header id="app-head">
        <Row style={{ height: "70px" }}>
          <Col
            xs={{ span: 0 }}
            lg={{ span: 2 }}
            xl={{ span: 3 }}
          >
            <div className="logo">
              <h1>
                <a href="/">{config.name}</a>
              </h1>
            </div>
          </Col>
          <Col xs={{ span: 0 }} lg={{ span: 11 }} xl={{ span: 11 }}>
            {title ? (
              <div className="title">
                <p>{title}</p>
              </div>
            ) : (
              <div className="siderNav">
                <SiderNav visible={this.state.visible} onClose={this.onClose} />
              </div>
            )}
          </Col>
          <Col xs={{ span: 0 }} lg={{ span: 11 }} xl={{ span: 10 }}>
            <div className="head_left">
              {buyUrl ? (
                <div className="buy-btn">
                  <a target="_blank" rel="noopener noreferrer" href={buyUrl}>
                    <Icon type="picture" theme="filled" />
                    {i18next.t("Buyingphotoframes")}
                  </a>
                </div>
              ) : null}
              <HeaderBar
                user={user}
                visible={this.state.visibleTop}
                onClose={this.onCloseTop}
              />
            </div>
          </Col>

          <Col xs={{ span: 8 }} lg={{ span: 0 }}>
            <div className="head_tab_left">
              {user ? (
                <Button icon="bars" size="large" onClick={this.menuList_show} />
              ) : (
                buyUrl ? <Button
                  icon="shopping-cart"
                  size="large"
                  href={buyUrl}
                  target="_blank"
                />: null
              )}
            </div>
          </Col>
          <Col xs={{ span: 8 }} lg={{ span: 0 }}>
            <div className="head_tab_conter">
              <a href="/">
                <img src={require('../../images/logo.png')} alt="Lovcube" />
              </a>
            </div>
          </Col>
          <Col xs={{ span: 8 }} lg={{ span: 0 }}>
            <div className="head_tab_right">
              {user ? (
                <div
                  className="head_tab_right_btn"
                  onClick={() => {
                    this.setState({ visibleTop: true });
                  }}
                >
                  <Avatar
                    src={
                      user.profile
                        ? `${host()}/uf/s/${user.profile.id}/${user.id}`
                        : null
                    }
                    size={40}
                    icon="user"
                  />
                  <Icon type="down" style={{ marginLeft: "5px" }} />
                </div>
              ) : (
                <div className="head_lang_r">
                  <Languageselect txtHide={true} />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </header>
    );
  }
}
