import { observable, action, remove, set } from 'mobx';
import { wsget, host } from '../../ws';
import CryptoJS from 'crypto-js';
import { fileSort, storage, adaptation, dataURLtoBlob, ajaxPromise, getCookie } from '../../util/index';
import { message } from 'antd';
import i18next from 'i18next';

//ls变量名称
const RAHMENSORT_2 = 'RahmenSort';
const RAHMENSORT_2_ISDES = 'RahmenSort_isdes';

const minH = 300;
const minW = 270;

const minW2 = 250;

//详情
const pminH = 285;
const pminW = 240;
const pminW2 = 230;

class rahmen {
  @observable firstInit = false; //第一次加载相框列表
  @observable bindframedis = false; //绑定相框模块
  @observable bindframevisible = false; //绑定相框模块
  @observable rahmenList = []; //相框列表
  @observable removerahmenList = []; //要删除的相框列表
  @observable playList = []; //相框的播放列表
  @observable frame_user_list = []; //绑定的用户列表
  @observable sumPhoto = 0; //总数照片
  @observable playframelist = null; //播放列表的相框列表
  @observable playitems = null; //播放列表
  @observable seletplay = []; //播放列表
  @observable noframes = false; //是否第一次绑定相框
  urlData = {}; //跳转详情页的url数据
  Newarr = [];
  flag = false;

  init = (bol) => {
    this.colse = bol;
  }

  //加载相框列表
  @action rahmen_init(cb, sort, isdes, width) {
    this.width = width;
    let i = sort || storage.get(RAHMENSORT_2);
    let is = isdes || storage.get(RAHMENSORT_2_ISDES);
    wsget('frame_list', null).then((data) => {
      this.playframelist = JSON.parse(JSON.stringify(data));
      let newArr = this.sort(observable(data), i, is);
      this.rahmenList = adaptation(width, newArr, minW, minH, minW2);
      cb && cb();
      this.firstInit = true;
    }).catch((res) => {
      if (window.ws) {
        if (window.ws.isconnected() && res.state !== 0) {
          this.rahmen_init(cb, sort, isdes, width);
        }
      }
    })
  }

  @action resize = (width, arr, flag) => {
    this.width = width;
    if (flag) {
      this.playList = adaptation(width, arr, minW, minH, minW2);
    } else {
      this.rahmenList = adaptation(width, arr, minW, minH, minW2);
    }
  }

  @action playframesort = (arr, bindframes, id) => {
    arr.forEach(item => {
      const idx = bindframes.findIndex(el => el.node === item.node);
      if (idx > -1) {
        if (item.playlist_id === id) {
          set(item, { default: true })
        }
        set(item, { check2: true })
      }
    });
  }

  //排序
  @action sort = (data, sort, isdes) => {
    if (isdes) {
      switch (sort) {
        case 0:
          //按绑定时间排序
          return fileSort.bindtime(data).reverse();
        case 1:
          //按文件名排序
          return fileSort.Filename(data).reverse();
        default:
          break;
      }
    } else {
      switch (sort) {
        case 0:
          //按绑定时间排序
          return fileSort.bindtime(data);
        case 1:
          //按文件名排序
          return fileSort.Filename(data);
        default:
          break;
      }
    }
  }

  //相框排序
  @action rahmen_sort = (i, isdes) => {
    this.rahmenList = this.sort(this.rahmenList, i, isdes)
  }

  //加载相框的播放列表详情
  @action rahmen_playlist_init(node, cb, width) {
    this.width = width;
    wsget('frame_playlist', node).then((data) => {
      this.playList = adaptation(width, observable(data), pminW, pminH, pminW2)
      this.sumPhoto = 0;
      data.forEach(el => {
        if (!el.filecount) {
          this.sumPhoto += 0;
        } else {
          this.sumPhoto += el.filecount;
        }
      });
      cb && cb();
    })
  }

  //选中相框列表
  @action pitchOn = (e, node, cb1, cb2) => {
    e.stopPropagation();
    let pos = (this.rahmenList || []).findIndex((item) => item.node === node);
    if (pos > -1 && this.rahmenList[pos].check) {
      remove(this.rahmenList[pos], 'check')
      this.removerahmenList.remove(node);
      cb2 && cb2();
    } else {
      set(this.rahmenList[pos], { check: true });
      this.removerahmenList.push(node);
      if (this.rahmenList.length === this.removerahmenList.length) {
        cb1 && cb1();
      }
    }
  }

  //全选相框列表
  @action checkAllrahmenList(checkAll) {
    if (this.removerahmenList.length !== this.rahmenList.length) {
      for (let i = 0; i < this.rahmenList.length; i++) {
        if (!this.rahmenList[i].check) {
          set(this.rahmenList[i], { check: true });
          this.removerahmenList.push(this.rahmenList[i].node);
        }
      }
    } else {
      for (let i = 0; i < this.rahmenList.length; i++) {
        remove(this.rahmenList[i], 'check')
      }
      this.removerahmenList = [];
    }
  }

  //取消选择
  @action cancelAll = () => {
    for (let i = 0; i < this.rahmenList.length; i++) {
      remove(this.rahmenList[i], 'check')
    }
    this.removerahmenList = [];
  }

  //反选
  @action invert = () => {
    this.removerahmenList = [];
    this.rahmenList.forEach(el => {
      if (el.check) {
        remove(el, 'check');
        this.removerahmenList.remove(el.node);
      } else {
        set(el, { check: true });
        this.removerahmenList.push(el.node);
      }
    })
  }

  //删除相框
  @action renameDelete = (id) => {
    if (id) {
      wsget('frame_delete', id, 1).then((res) => {
        this.rahmenList = this.rahmenList.filter(item => item.node !== id[0]);
        this.removerahmenList = [];
      })
    } else {
      wsget('frame_delete', this.removerahmenList, 1).then((res) => {
        let arr = this.rahmenList.filter(item => {
          return this.removerahmenList.indexOf(item.node) === -1;
        });
        this.rahmenList = arr;
        this.playframelist = arr;
        this.removerahmenList = [];
      })
    }
  }

  //相框重命名
  @action rename_rename(node, name) {
    let data = { node, framename: name.toString() }
    wsget('frame_rename', data, 1).then((res) => {
      const i = this.rahmenList.findIndex(item => item.node === node);
      this.rahmenList[i].name = name;
      this.playframelist[i].name = name;
    })
  }

  //添加相框
  @action bindSucceed(node, name, token, id) {
    let data = {
      node,
      framename: name,
      playlist_id: id,
      token
    }
    wsget('frame_after_add', data).then(() => {
      wsget('frame_list', null).then((res) => {
        this.resize(this.width, observable(res));
      })
    })
  }

  //设置默认播放列表
  @action set_default = (node, playlist_id) => {
    let data = {
      node, playlist_id
    }
    wsget('frame_set_default_playlist', data, 1).then((res) => {
      let idx = this.playList.findIndex(item => item.id === playlist_id);
      this.playList.forEach(el => {
        remove(el, 'isdefault')
      });
      set(this.playList[idx], { isdefault: true });
    })
  }

  //推送相框播放
  @action frame_play = (node, playlist_id) => {
    const data = {
      node, playlist_id
    }
    wsget('frame_play', data, 1)
  }

  //删除指定相框的播放列表
  @action frame_playlist_delete(node, id) {
    let data = {
      node, playlist_id: id
    }
    wsget('frame_playlist_delete', data, 1).then((res) => {
      this.playList = this.playList.filter(item => item.id !== id);
      this.sumPhoto = 0;
      this.playList.forEach(el => {
        if (!el.filecount) {
          this.sumPhoto += 0;
        } else {
          this.sumPhoto += el.filecount;
        }
      });
    })
  }

  //绑定的用户列表
  @action get_user_list(node, cb) {
    wsget('frame_user_list', node).then((data) => {
      this.frame_user_list = data;
      cb && cb();
    })
  }

  //设置指定用户昵称
  @action frame_user_rename = (node, id, name) => {
    const data = {
      node,
      user_id: id,
      nickname: name
    }
    wsget('frame_user_rename', data, 1).then((res) => {
      let pos = this.frame_user_list.findIndex(item => item.id === id)
      this.frame_user_list[pos].nickname = name;
    })
  }

  //设置管理员
  @action frame_user_admin = (node, id, boole) => {
    const data = {
      node,
      user_id: id,
      admin: boole
    }
    wsget('frame_user_admin', data, 1).then((res) => {
      let pos = this.frame_user_list.findIndex(item => item.id === id)
      if (boole === 'false') {
        remove(this.frame_user_list[pos], 'is_admin');
        return;
      }
      set(this.frame_user_list[pos], { 'is_admin': boole })
    })
  }

  //删除相框用户
  @action frame_user_delete = (node, id) => {
    // node: 相框节点, user_id: ⽤户id
    const data = {
      node,
      user_id: id
    }
    wsget('frame_user_delete', data, 1).then((res) => {
      let pos = this.frame_user_list.findIndex(item => item.id === id);
      this.frame_user_list.remove(this.frame_user_list[pos]);
    })
  }

  //选择列表
  @action resetPlayitems=()=>{
    this.playitems = null;
  }
  @action selectedlist = (cb) => {
    this.seletplay = [];
    wsget('playlist_list', null, 0).then((data) => {
      this.playitems = fileSort.update(observable(data));
      this.playList.forEach(item => {
        let pos = this.playitems.findIndex(el => el.id === item.id);
        set(this.playitems[pos], { check: true });
        this.seletplay.push(this.playitems[pos].id);
        if (item.isdefault) {
          let pos = this.playitems.findIndex(el => el.id === item.id);
          set(this.playitems[pos], { defaultlistId: true })
        }
      })
      cb&&cb();
    })
  }
  //选择
  @action Check = (id) => {
    const pos = this.playitems.findIndex(el => el.id === id);
    if (pos > -1) {
      let item = this.playitems[pos];
      if (item.defaultlistId) {
        return;
      }
      this.flag = true;
      if (item.check) {
        remove(item, 'check');
        this.seletplay.remove(item.id);
      } else {
        set(item, { check: true })
        this.seletplay.push(item.id);
      }
    }
  }
  //全选
  @action allCheck = () => {
    this.flag = true;
    if (this.seletplay.length !== this.playitems.length) {
      this.seletplay = [];
      this.playitems.forEach(item => {
        set(item, { check: true })
        this.seletplay.push(item.id);
      })
    } else {
      this.playitems.forEach(item => {
        if (!item.defaultlistId) {
          remove(item, 'check');
          this.seletplay.remove(item.id);
        }
      })
    }
  }
  //添加播放列表
  @action add_playlist(node) {
    if (this.flag) {
      let default_pl_id = null;
      const pos = this.playitems.findIndex(el => { return el.defaultlistId })
      default_pl_id = this.playitems[pos].id;
      const data = {
        node,
        playlists: [...this.seletplay],
        default: default_pl_id
      }
      wsget('frame_playlist_save', data, 1).then(() => {
        this.rahmen_playlist_init(node, null, this.width);
        this.playitems = null;
      })
    }
  }

  //上传相框封面
  @action uploadCover = (cropper, file, node, cb1, cb2) => {
    this.node = node;
    const key = 'portrait';
    message.loading({ content: 'uploading...', key, duration: 0 });
    if (this.colse) cb2();
    let afterimg = cropper.getCroppedCanvas({
      imageSmoothingQuality: 'high'
    }).toDataURL('image/jpeg');
    let wordArray = CryptoJS.lib.WordArray.create(afterimg);
    const hash = CryptoJS.SHA256(wordArray).toString();
    let blob = dataURLtoBlob(afterimg);
    ajaxPromise(blob, `${host()}/up/p/${getCookie('lovtok')}/${hash}/${file.name}`)
      .then((result) => {
        message.success({ content: 'succeed!', key, duration: 2 });
        const frame_profile_id_data = {
          node: this.node,
          profile_id: result.id
        }
        wsget('frame_profile_id_save', frame_profile_id_data).then((res) => {
          message.success({ content: 'succeed!', key, duration: 2 });
          if (this.colse) cb1();
        }).catch((err) => {
          console.log(err);
          message.error({ content: 'come to nothing', key, duration: 3 })
        })
      }).catch(() => {
        message.error({ content: 'come to nothing', key, duration: 3 })
      })
  }

  //绑定相框模块隐藏
  @action frameBindElhide() {
    // this.bindframedis = true;
    this.bindframevisible = false;
  }
  //绑定相框模块显示
  @action frameBindElshow() {
    this.bindframedis = false;
  }
  @action bindframevisibleshow = () => {
    this.noframes = false;
    let data = storage.get('user');
    if (!data.frames) {
      this.noframes = true;
    }
    this.bindframevisible = true;
    if (this.bindframevisible) {
      this.bindframedis = false;
    }
  }
  //第一次绑定相框
  @action fristbind = () => {
    this.noframes = true;
  }

  //遥控
  @action telecontrol = (txt, node) => {
    const key = 'telecontrol';
    message.loading({ content: `${i18next.t('Responding')}...`, key, duration: 0 });
    let data = { node };
    switch (txt) {
      case 'close':
        data['cmd'] = 'power';
        break;

      case 'return':
        data['cmd'] = 'back';
        break;

      case 'family':
        data['cmd'] = 'home';
        break;

      case 'top':
        data['cmd'] = 'up';
        break;

      case 'bottom':
        data['cmd'] = 'down';
        break;

      case 'left':
        data['cmd'] = 'left';
        break;

      case 'right':
        data['cmd'] = 'right';
        break;

      case 'middle':
        data['cmd'] = 'enter';
        break;

      case 'pic':
        data['cmd'] = 'slide';
        break;

      case 'mute':
        data['cmd'] = 'mute';
        break;

      case 'menu':
        data['cmd'] = 'menu';
        break;

      default:
        break;
    }
    wsget('frame_remote', data).then((res) => {
      message.success({ content: i18next.t('successful'), key, duration: 2 });
    }).catch((res) => {
      if (res.state === 127) {
        message.error({ content: i18next.t('Framenotonline'), key, duration: 4 });
        return;
      }
      message.error({ content: i18next.t('comeNothing'), key, duration: 2 });
    })
  }

  //重置
  @action reset0 = () => {
    this.sumPhoto = 0;
  }

  //跳转详情页的url数据存储
  @action goUrl = (obj) => {
    this.urlData = obj;
  }

  @action clearData = () => {
    this.firstInit = false; //第一次加载相框列表
    this.bindframedis = false; //绑定相框模块
    this.bindframevisible = false; //绑定相框模块
    this.rahmenList = []; //相框列表
    this.removerahmenList = []; //要删除的相框列表
    this.playList = []; //相框的播放列表
    this.frame_user_list = []; //绑定的用户列表
    this.sumPhoto = 0; //总数照片
    this.urlData = {}; //跳转详情页的url数据
    this.framelist = null;
    this.noframes = false;
  }


}

export default new rahmen()
