
import en from './en'

import 'dayjs/locale/en'

export const langList = [
{ name: en.name, key: en.key },
]

export const languageCells = [
en.name,
]

export const languageKeys = [
en.key,
]

export const translations = {
en,
}
