// 实现一个简单的消息机制，避免系统的冲突，提高效率
// 由于是内部使用，为了提高效率，不对数据进行合法性检查

class event {
  static refs = {}
  static id = 1

  static on(en, back) {
    const eid = event.id++
    if (!event.refs[en]) {
      event.refs[en] = {}
    }
    event.refs[en][eid] = back
    return [en, eid]
  }

  static rm(x) {
    if (!x){return}
    const d = event.refs[x[0]]
    if (d) {
      delete d[x[1]]
      if (!Object.keys(d).length) {
        delete event.refs[x[0]]
      }
    }
  }

  // 删除所有的指定类型的事件
  static rmName(e) {
    if (!e){return}
    delete(event.refs[e])
  }

  static rmAll() {
    event.refs = {}
  }

  static emit(en, dt) {
    const o = event.refs[en]
    if (o && Object.keys(o).length > 0) {
      setTimeout(()=>{
        for (let i in o) {
          o[i](dt)
        }
      },0)
    }
  }
}

export default event
