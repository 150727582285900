import i18next from 'i18next';
import { message } from 'antd';
import config from "./config";
import { storage, setCookie, shuffle } from "./util/index";
import WsMQ, { GET_TIMEOUT } from "./util/wsmq";
import event from "./util/event";
import login from "./store/login/index";
import { dec } from "./util/b64";
import i18n from './i18n'

// 一些配置信息
let HOSTS; // 主机列表
let WSOK; // 最后一次登录的服务器地址
let IPINFO; // 记录最后一次登录的IP地址和地区信息
let TRYCOUNT = 0; // 重试的次数，用于判断是否获取新的连接地址

let CONNECT = false; // eslint-disable-line

// 如果renew为真则强行获取新的服务节点
async function nexthost(renew) {
  TRYCOUNT++;
  if (!IPINFO) {
    IPINFO = await storage.get("ipinfo");
  }
  // 检查最后一次登录
  if (!WSOK) {
    WSOK = await storage.get("wsok");
  }
  if (!HOSTS) {
    HOSTS = await storage.get("hosts");
    if (!HOSTS || HOSTS.length < 1) {
      HOSTS = JSON.parse(dec(config.serverList));
    }
  }

  // 检查当前网络是否已经连接
  if (!navigator.onLine) {
    console.log("[network is unvisible]", navigator.onLine);
    return null;
  }

  if (!renew && WSOK && WSOK.domain && WSOK.try < 1) {
    WSOK.try = (WSOK.try || 0) + 1;
    return WSOK;
  }
  if (!HOSTS) {
    return null;
  }
  HOSTS = shuffle(HOSTS);
  const l = HOSTS.length;
  // 获取一个可靠的地址
  if (
    (IPINFO && !WSOK) ||
    (IPINFO &&
      WSOK &&
      IPINFO.city !== WSOK.city &&
      IPINFO.country !== WSOK.country &&
      IPINFO.continent !== WSOK.continent)
  ) {
    // 获取同城市或者国家的节点，首先打乱节点顺序
    for (let i = 0; i < l; i++) {
      const h = HOSTS[i];
      if ((!WSOK || h.domain !== WSOK.domain) && h.city === IPINFO.city) {
        h.try = 1;
        WSOK = h;
        return h;
      }
    }
    for (let i = 0; i < l; i++) {
      const h = HOSTS[i];
      if ((!WSOK || h.domain !== WSOK.domain) && h.country === IPINFO.country) {
        h.try = 1;
        WSOK = h;
        return h;
      }
    }
    for (let i = 0; i < l; i++) {
      const h = HOSTS[i];
      if (
        (!WSOK || h.domain !== WSOK.domain) &&
        h.continent === IPINFO.continent
      ) {
        h.try = 1;
        WSOK = h;
        return h;
      }
    }
  }
  for (let i = 0; i < l; i++) {
    const h = HOSTS[i];
    if (!WSOK || h.domain !== WSOK.domain) {
      h.try = 1;
      WSOK = h;
      return h;
    }
  }

  // 返回一个随机地址
  return HOSTS[Math.floor(Math.random() * l)];
}

function okhost(h) {
  h.try = 0;
  WSOK = h;
  storage.set("wsok", h);
  // 判断是否需要获取新的IP地址
  if (TRYCOUNT > 3) {
    wsget("server_list").then((nhosts) => {
      const x = {};
      for (let i = 0, l = nhosts.length; i < l; i++) {
        x[nhosts[i].domain] = nhosts[i];
      }
      for (let i = 0, l = HOSTS.length; i < l; i++) {
        const o = HOSTS[i];
        if (!x[o.domain]) {
          x[o.domain] = o;
        }
      }
      HOSTS = Object.values(x).slice(0, 50);
      storage.set("hosts", HOSTS);
    });
  }
  TRYCOUNT = 0;
}

export function autoLogin() {
  const user = storage.get("user");
  if (user && user.name && user.pwd) {
    login_in(user);
  } else {
    if (localStorage.getItem("user") !== null) {
      event.emit("isAutoLogin", false);
    }
  }
}

function login_in(user) {
  const { name, pwd } = user;
  wsget("user_login", { name: name, pwd: pwd })
    .then((data) => {
      login.login_in2(data);
      storage.set("user", { ...user, ...data });
      setCookie("lovtok", data.token, 0);
      event.emit("isAutoLogin", true);
    })
    .catch((er) => {
      if (er.state === 0) {
        //登录失败 退出登录
        event.emit("isAutoLogin", false);
      }
    });
}

export default function ws() {
  if (!window.wsx) {
    let conStart;
    window.wsx = new WsMQ(
      `/api/web/${i18n.locale}/${config.vernum}`,
      nexthost,
      async (h) => {
        // openfun
        console.log("Connected  ", `${h.domain}:${h.port}`, `  try:${h.try}`);
        conStart = new Date();
        autoLogin();
        okhost(h);
        event.emit("wsinit", 1);
        CONNECT = true;
        // 获取最新地址信息
        const now = conStart.getTime();
        if (!IPINFO || (IPINFO.updated || 0) + 24 * 3600 * 1000 < now) {
          wsget("ip_info").then((hx) => {
            hx.updated = now; // 记录更新
            IPINFO = hx;
            storage.set("ipinfo", IPINFO);
          });
        }
      },
      async (h) => {
        // closefun
        // const conEnd = new Date();
        event.emit("wsinit", 2);
        CONNECT = false;
      }
    );
    let last = +new Date();
    event.on("reconnect", (renew) => {
      console.log("[reconnect emit]", renew);
      const now = +new Date();
      // 为了防止重连风暴，需要间隔1分钟才能重连
      if (now - last > 60 * 1000) {
        last = now;
        ws().reconnect(renew);
      }
    });
  }
  return window.wsx;
}

/**
 *
 * @param {string} cmd //命令
 * @param {*} indata  //接口数据
 * @param {0/1} ismsg //等待提示消息，非必须(可不传)，默认0不显示，为1时显示
 * @param {*} timeout //非必须，可以不传
 */
export function wsget(cmd, indata, ismsg, timeout) {
  // console.log("[WSGET]->->->", cmd, indata);
  const key = "Globalnews";
  ismsg && message.loading({ content: i18next.t("loading"), key, duration: 0 });
  return new Promise((resolve, reject) => {
    ws().get(
      cmd,
      indata,
      (state, data) => {
        if (state !== 1) {
          // eslint-disable-line
          // console.warn("wsget (cmd, indata, state):", cmd, indata, state); // eslint-disable-line
          ismsg &&
            message.error({
              content: i18next.t("comeNothing"),
              key,
              duration: 3,
            });
          reject({ state, data });
          if (state === 0 && cmd !== "user_login") {
            // 有可能是没有登录，先登录
            autoLogin();
          } else if (state === GET_TIMEOUT) {
            ws().reconnect(true);
          }
        } else {
          // console.log("[WSGET]<-", cmd, state, data);
          ismsg &&
            message.success({
              content: i18next.t("successful"),
              key,
              duration: 2,
            });
          resolve(data);
        }
      },
      timeout
    );
  });
}

// 获取当前连接的host
export function host() {
  return ws().geturl();
}

setTimeout(ws, 9);
