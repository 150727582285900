import React from "react";
import { Drawer, Menu } from "antd";
import { withRouter, NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";
import "./style.scss";

@withRouter
@withTranslation()
class SiderNav extends React.Component {
  state = {
    path: "",
    visible: false,
  };

  componentDidMount() {
    this.setState({ path: this.props.location.pathname });
    document.addEventListener("click", this.hide);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ path: nextProps.location.pathname });
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.hide);
  }

  show = (e) => {
    e.nativeEvent.stopImmediatePropagation();
    this.setState({ visible: !this.state.visible });
  };

  hide = (e) => {
    this.setState({ visible: false });
  };

  render() {
    const { t, onClose, visible } = this.props;
    const nav = [
      {
        title: t("homepage"),
        key: "/home",
      },
      {
        title: t("playlist"),
        key: "/playlist",
      },
      {
        title: t("cloudalbum"),
        key: "/album",
      },
      {
        title: t("photoframe"),
        key: "/frame",
      },
      // {
      //   title: t('Medialibrary'),
      //   key: '/medialibrary'
      // }
    ];
    
    return (
      <div className="nav-inner">
        <div className="nav">
          {nav.map((item) => {
            return (
              <NavLink key={item.key} to={item.key}>
                <span>{item.title}</span>
              </NavLink>
            );
          })}
        </div>

        <Drawer
          placement="left"
          closable={false}
          onClose={onClose}
          visible={visible}
          width={window.innerWidth < 400 ? 200 : 256}
        >
          <Menu defaultSelectedKeys={[this.state.path]} onClick={onClose}>
            {nav.map((item) => {
              return (
                <Menu.Item key={item.key}>
                  <NavLink to={item.key}>{item.title}</NavLink>
                </Menu.Item>
              );
            })}
          </Menu>
        </Drawer>
      </div>
    );
  }
}

export default SiderNav;
