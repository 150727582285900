import apple from '../images/apple.jpg';
// import download from '../images/download.jpg';
import google from '../images/google.jpg';


export const qrArr = [
  {url:'https://apps.apple.com/us/app/lovcube/id1499615328',img:apple}, //[AppleUrl]
  {url:'https://play.google.com/store/apps/details?id=com.lovcubeframe',img:google}, //[GoogleUrl]
  // /*download*/{url:'https://download.com/',img:download},
]


export const buyUrl = 'https://lovcube.com/';
