import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Icon, Tooltip } from 'antd';
import { Footbtn } from './Footbtn';
import Selectlist from './Selectlist';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { trim } from '../../util/index';

@withTranslation() @observer
class Success extends Component {
  constructor(props) {
    super(props);
    this.nameRef = React.createRef();
  }
  @observable visible = false;
  @observable farmedata = {};


  componentDidMount() {
    this.farmedata.id = this.props.defaultplaylistId;
  }

  UNSAFE_componentWillReceiveProps(next) {
    if (this.props.defaultplaylistId !== next.defaultplaylistId) {
      this.farmedata.id = next.defaultplaylistId;
    }
  }



  onOK = () => {
    if (this.props.disabled) {
      return;
    }
    let val = trim(this.nameRef.current.value);
    this.props.handleconfirm(val, this.farmedata)
  }

  handleOk = (data) => {
    this.farmedata = data;
    this.visible = false;
    this.props.handleOk(data)
  }

  onplaylist = () => {
    this.visible = true;
  }

  cancel = () => {
    this.visible = false;
  }

  render() {
    const {
      t,
      disabled,
      handlecancel,
      select_name,
      already,
      noframes,
      bindloading,
      cancelBtn } = this.props;
    return (
      <div>
        <div className='framebind-succeed'>
          <p className='succeed-icon'><Icon type="check-circle" theme="filled" /></p>
          {already && <p className='already'>{t('Framealreadybound')}</p>}
          <p className='p-txt'>{already ? t('ModifynameList') : t('nameList')}</p>
        </div>
        <div className='succeed-input'>
          <input id='framename' maxLength={50} ref={this.nameRef} type="text" placeholder={t('frameName')} />
        </div>
        <div className='default-playlist'>
          <h5>{t('DefaultPlaylist')}:</h5>
          <Tooltip placement="top" overlayClassName='tooltip' title={select_name}>
            <span className='select-list-name ellipsis'>{select_name}</span>
          </Tooltip>
          <div className='list-boby'>
            <span
              className='newlist-btn'
              onClick={this.onplaylist}>{t('DefaultPlaylist')}</span>
          </div>
        </div>

        <Footbtn
          disabled={disabled}
          noframes={noframes}
          bindloading={bindloading}
          cancelBtn={cancelBtn}
          handlecancel={handlecancel}
          handleconfirm={this.onOK} />

        {this.visible && <Selectlist
          farmedata={this.farmedata}
          cancel={this.cancel}
          handleOk={this.handleOk}
        />}
      </div>
    );
  }
}

export default Success;
