import CryptoJS from "crypto-js";
import { host } from "../ws";
import { set } from "mobx";
import { enc, dec } from "./b64";

//加载文件url
export const getFile = {
  file_s(id) {
    return `${host()}/f/s/${id}/${getCookie("lovtok")}`;
  },
  file_b(id) {
    return `${host()}/f/b/${id}/${getCookie("lovtok")}`;
  },
  feedback_s(id, user_id) {
    return `${host()}/uf/s/${id}/${user_id}`;
  },
  feedback_b(id, user_id) {
    return `${host()}/uf/b/${id}/${user_id}`;
  },
};



/**
 * 上传Ajax
 *
 * @param data 上传数据对象
 * @param url 上传地址
 * @param fileId 文件id
 *
 * 在percent与fileId存在的时候才回监控上传进度
 *
 */
export const ajaxPromise = (data, url, fileId) => {
  let promiseReject;

  const promise = new Promise((resolve, reject) => {
    promiseReject = reject;

    const xhr = new XMLHttpRequest();
    xhr.open("POST", url);
    xhr.onreadystatechange = () => {
      // eslint-disable-next-line
      if (xhr.readyState == 4) {
        // eslint-disable-next-line
        if (xhr.status == 200) {
          try {
            let response = JSON.parse(xhr.responseText);
            if (fileId) {
              resolve({
                data: response,
                fileid: fileId,
              });
            } else {
              resolve(response);
            }
          } catch (error) {
            reject({
              fileid: fileId,
              status: xhr.status,
            });
          }
        } else {
          reject({
            fileid: fileId,
            status: xhr.status,
          });
        }
      }
    };
    xhr.upload.addEventListener(
      "progress",
      (e) => {
        if (e.lengthComputable && promise.onUploadProgress) {
          promise.onUploadProgress(e.loaded, e.total, fileId);
        }
      },
      false
    );
    xhr.send(data);
  });

  promise.uploadProgress = (fn) => {
    fn = fn || function () {};
    promise.onUploadProgress = fn;
    return promise;
  };

  promise.cancel = () => {
    promiseReject(new Error("canceled"));
  };

  return promise;
};

export const dataURLtoBlob = (dataurl) => {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

/**
 * 转hash
 *
 * @param files 文件信息对象
 * @param obj 图片质量
 * @param maxW/maxH 图片最大高宽
 *
 */
export const changeHash = (files, obj, maxW, maxH) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    if (/image\/\w+/.test(files.file.type)) {
      reader.readAsDataURL(files.file);
      reader.onload = () => {
        let blob;
        let hash;
        let imgtype = files.file.type.split("/")[1];
        const img = new Image();
        img.src = reader.result;
        let wordArray = CryptoJS.lib.WordArray.create(reader.result);
        hash = CryptoJS.SHA256(wordArray).toString();
        img.onload = () => {
          try {
            if (imgtype === "gif") {
              blob = dataURLtoBlob(reader.result);
            } else {
              // 图片原始尺寸
              let originWidth = img.width,
                originHeight = img.height,
                scale = originWidth / originHeight;
              let targetWidth, targetHeight;
              if (originWidth > maxW || originHeight > maxH) {
                if (originWidth > maxW) {
                  // 宽大于maxW
                  targetWidth = maxW;
                  targetHeight = targetWidth / scale;
                } else {
                  // 高大于maxH
                  targetHeight = maxH;
                  targetWidth = scale * targetHeight;
                }
              } else {
                targetWidth = originWidth;
                targetHeight = originHeight;
              }
              let quality = obj.quality || 0.7;
              let canvas = document.createElement("canvas");
              let ctx = canvas.getContext("2d");
              canvas.width = targetWidth;
              canvas.height = targetHeight;
              ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
              if (obj.quality && obj.quality <= 1 && obj.quality > 0) {
                quality = obj.quality;
              }
              let base64;
              if (imgtype === "bmp") {
                base64 = canvas.toDataURL("image/png", quality);
              } else if (imgtype === "png") {
                base64 = canvas.toDataURL("image/png", quality);
              } else {
                base64 = canvas.toDataURL("image/jpeg", quality);
              }
              blob = dataURLtoBlob(base64);
            }
            resolve({
              state: 1,
              data: {
                id: files.id,
                blob,
                hash,
              },
            });
          } catch (error) {
            reject({ state: 0 });
          }
        };
        img.onerror = () => {
          reject({ state: 0 });
        };
      };
      reader.onerror = () => {
        reject({ state: 0 });
      };
    } else {
      reader.readAsArrayBuffer(files.file);
      reader.onload = (e) => {
        try {
          let wordArray = CryptoJS.lib.WordArray.create(reader.result);
          const hash_v = CryptoJS.SHA256(wordArray).toString();
          resolve({
            state: 1,
            data: {
              id: files.id,
              blob: e.target.result,
              hash: hash_v,
            },
          });
        } catch (error) {
          reject({ state: 0 });
        }
      };
      reader.onerror = () => {
        reject({ state: 0 });
      };
    }
  });
};

//大小转换
export const byteConvert = (bytes) => {
  if (isNaN(bytes)) {
    return "";
  }
  var symbols = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  var exp = Math.floor(Math.log(bytes) / Math.log(2));
  if (exp < 1) {
    exp = 0;
  }
  var i = Math.floor(exp / 10);
  bytes = bytes / Math.pow(2, 10 * i);

  if (bytes.toString().length > bytes.toFixed(2).toString().length) {
    bytes = bytes.toFixed(2);
  }
  return bytes + " " + symbols[i];
};

//图片加载
export const imgLoad = (url, callback) => {
  let oImg = new Image(); //创建一个Image对象,实现图片的预加载
  oImg.onload = () => {
    //图片下载完毕时异步调用callback函数
    callback && callback(oImg.src, oImg);
  };
  oImg.src = url; // 应当先进行onload的绑定,再赋值给src
};

/**
 * 设置cookie
 * @param name cookie的名称
 * @param value cookie的值
 * @param day cookie的过期时间
 */
export const setCookie = function (name, value, day) {
  if (day > 0) {
    //当设置的时间等于0时，不设置expires属性，cookie在浏览器关闭后删除
    var expires = day * 24 * 60 * 60 * 1000;
    var date = new Date(+new Date() + expires);
    document.cookie =
      name + "=" + escape(value) + ";expires=" + date.toUTCString();
  } else {
    document.cookie = name + "=" + escape(value);
  }
};

/**
 * 获取对应名称的cookie
 * @param name cookie的名称
 * @returns {null} 不存在时，返回null
 */
export const getCookie = function (name) {
  var arr = document.cookie.split("; "); //多个cookie值是以; 分隔的，用split把cookie分割开并赋值给数组
  for (
    var i = 0;
    i < arr[i].length;
    i++ //历遍数组
  ) {
    var arr2 = arr[i].split("="); //原来割好的数组是：user=simon，再用split('=')分割成：user simon 这样可以通过arr2[0] arr2[1]来分别获取user和simon
    if (arr2[0] === name) {
      //如果数组的属性名等于传进来的name
      return arr2[1]; //就返回属性名对应的值
    }
  }
  return null; //没找到就返回空
};

//删除cookie
export const removeCookie = function (name) {
  setCookie(name, 1, -1);
};

//sessionStorage
export const session = {
  /**
   * 根据键值获取sessionStorage
   * @param key 键
   * @returns 失败false,成功对象
   */
  get(key, defaultValue) {
    try {
      return JSON.parse(dec(sessionStorage.getItem(key)));
    } catch (e) {
      //console.log(e)
    }
    return defaultValue;
  },
  /**
   * 设置sessionStorage
   * @param key 键
   * @param value 值   String或者JSON或者ARRAY
   * @returns {boolean}
   */
  set(key, value) {
    sessionStorage.setItem(key, enc(JSON.stringify(value)));
  },
  /**
   * 移除sessionStorage
   * @param key
   * @returns {boolean}
   */
  remove(key) {
    sessionStorage.removeItem(key);
  },
  /**
   * 清空所有
   * @returns {boolean}
   */
  clearAll() {
    if (typeof Storage !== "undefined") {
      sessionStorage.clear();
      return true;
    } else {
      return false;
    }
  },
};

//localStorage
export const storage = {
  set(keyname, data) {
    localStorage.setItem(keyname, enc(JSON.stringify(data)));
  },
  get(keyname, defaultValue) {
    try {
      return JSON.parse(dec(localStorage.getItem(keyname)));
    } catch (ex) {
      //console.log(ex)
    }
    return defaultValue;
  },
  remove(keyname) {
    localStorage.removeItem(keyname);
  },
};

/**
 * 分割数组创建二维数组封装
 * @param data 数组
 * @param senArrLen 需要分割成子数组的长度
 */
export const splitArr = (data, senArrLen) => {
  //处理成len个一组的数据
  let data_len = data.length;
  let arrOuter_len =
    data_len % senArrLen === 0
      ? data_len / senArrLen
      : parseInt(data_len / senArrLen + "") + 1;
  let arrSec_len = data_len > senArrLen ? senArrLen : data_len; //内层数组的长度
  let arrOuter = new Array(arrOuter_len); //最外层数组
  let arrOuter_index = 0; //外层数组的子元素下标
  for (let i = 0; i < data_len; i++) {
    if (i % senArrLen === 0) {
      arrOuter_index++;
      let len = arrSec_len * arrOuter_index;
      //将内层数组的长度最小取决于数据长度对len取余，平时最内层由下面赋值决定
      arrOuter[arrOuter_index - 1] = new Array(data_len % senArrLen);
      if (arrOuter_index === arrOuter_len)
        //最后一组
        data_len % senArrLen === 0
          ? (len = (data_len % senArrLen) + senArrLen * arrOuter_index)
          : (len = (data_len % senArrLen) + senArrLen * (arrOuter_index - 1));
      let arrSec_index = 0; //第二层数组的索引
      for (let k = i; k < len; k++) {
        //第一层数组的开始取决于第二层数组长度*当前第一层的索引
        arrOuter[arrOuter_index - 1][arrSec_index] = data[k];
        arrSec_index++;
      }
    }
  }
  return arrOuter;
};

//文件排序，默认降序排列
export const fileSort = {
  //创建时间排序
  create(arr) {
    let result = arr.sort((a, b) => {
      return time(b.created) - time(a.created);
    });
    return result;
  },
  //更新时间排序
  update(arr) {
    arr.forEach((item) => {
      if (!item.updated) {
        try {
          set(item, { updated: item.created });
        } catch (error) {
          item.updated = item.created;
        }
      }
    });
    let result = arr.sort((a, b) => {
      return time(b.updated) - time(a.updated);
    });
    return result;
  },
  //文件数量排序
  filecount(arr) {
    arr.forEach((item) => {
      if (!item.filecount) {
        try {
          set(item, { filecount: 0 });
          set(item, { updated: item.created });
        } catch (error) {
          item.filecount = 0;
          item.updated = item.created;
        }
      }
    });
    let result = arr.sort((a, b) => {
      return b.filecount - a.filecount;
    });
    return result;
  },
  //绑定时间降序
  bindtime(arr) {
    let result = arr.sort((a, b) => {
      return time(b.bindtime) - time(a.bindtime);
    });
    return result;
  },
  //名字排序
  Filename(arr) {
    let result = arr.sort((a, b) => {
      return b.name.localeCompare(a.name);
    });
    return result;
  },
};

//时间格式转换
export const time = (t) => {
  if (typeof t !== "string") {
    return;
  }
  let str;
  if (t.indexOf(".") !== -1) {
    str = t.split(".")[0].split("T").join(" ");
  } else {
    str = t.split("+")[0].split("T").join(" ");
  }
  let date = new Date(str);
  let timestamp = date.getTime(); //时间戳

  return timestamp;
};

//自适应计算
export const adaptation = (width, arr, minW, minH, minW2) => {
  let FWidth;
  let origw = minW;
  let origh = minH;
  if (width < 1200) {
    origw = minW2;
    origh = (origw / minW) * minH;
  }
  if (width < 550) {
    origw = 180;
    origh = 210;
    origh = (origw / origw) * origh;
  }
  if (os().isPhone || os().isAndroid || os().isTablet) {
    FWidth = width;
  } else {
    FWidth = width - 18;
  }
  let col = Math.floor(FWidth / origw);
  let space = FWidth % origw;
  let average = space / col;
  let w = origw + average;
  let h = (w / origw) * origh;
  arr.forEach((item) => {
    set(item, { w });
    set(item, { h });
  });
  return arr;
};

/**
 * 自适应计算
 * @param width 外盒子的宽度
 * @param w 子元素宽度
 * @param h 子元素高度
 */
export const selfAdaption = (width, w, h, w2, h2, screen = 500) => {
  let FWidth;
  let origw = w;
  let origh = h;
  if (os().isPhone || os().isAndroid || os().isTablet) {
    FWidth = width;
  } else {
    FWidth = width - 18;
  }
  if (width < screen) {
    origw = w2;
    origh = h2;
  }
  let row = Math.floor(FWidth / origw);
  let space = FWidth % origw;
  let wi = space / row + origw;
  let hi = (wi / origw) * origh;
  let colNum = Math.floor(FWidth / origw);
  return {
    wi,
    hi,
    colNum,
  };
};

/**
 * 自适应计算2
 * @param width 外盒子的宽度
 * @param w 子元素宽度
 * @param h 子元素高度
 */
export const selfAdaption2 = (width, w = 215, w2) => {
  let innerWidth;
  let origw = w;
  // let origh = h;
  if (width < 500) {
    w2 ? (origw = w2) : (origw = 180);
  }
  if (os().isPhone || os().isAndroid || os().isTablet) {
    innerWidth = width;
  } else if (width > 500) {
    innerWidth = width - 48;
  }
  let row = Math.floor(innerWidth / origw);
  let space = innerWidth % origw;
  let wi = space / row + origw;
  return wi;
};

//字符串每4个字符中间加空格
export const strRep = (str) => {
  let newstr = str.replace(/[^\d]/g, "").replace(/(\d{4})(?=\d)/g, "$1 ");
  return newstr;
};

//删除左右两端的空格
export const trim = (str) => {
  return str.replace(/(^\s*)|(\s*$)/g, "");
};

/**
 * 数组根据条件过滤
 * @param arr 数组
 * @param el 过滤的元素 type:str
 * @param str 过滤条件
 */
export const myfilter = (arr, el, str) => {
  let newArr = arr.filter((item) => {
    return item[el].indexOf(str) !== -1;
  });
  return newArr;
};

/**
 * 检验字符串是否含有特殊字符
 * @param str 字符串
 */
export const taskStr = (str) => {
  // const regEn = /[`~!@#$%^&*()_+-=<>?:"{},.\\/;'[\]]/im;
  // const regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\]]/im;
  const reg = /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/;
  if (reg.test(str)) {
    return true;
  }
  return false;
};

/**
 * 检验邮箱
 * @param str 字符串
 */
export const checkEmail = (str) => {
  const reg = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$/;
  if (reg.test(str)) {
    return true;
  } else {
    return false;
  }
};

//判断平台
export const os = () => {
  let ua = navigator.userAgent,
    isWindowsPhone = /(?:Windows Phone)/.test(ua),
    isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
    isAndroid = /(?:Android)/.test(ua),
    isFireFox = /(?:Firefox)/.test(ua),
    isChrome = /(?:Chrome|CriOS)/.test(ua), //eslint-disable-line
    isTablet =
      /(?:iPad|PlayBook)/.test(ua) ||
      (isAndroid && !/(?:Mobile)/.test(ua)) ||
      (isFireFox && /(?:Tablet)/.test(ua)),
    isPhone = /(?:iPhone)/.test(ua) && !isTablet,
    isPc = !isPhone && !isAndroid && !isSymbian;
  return {
    isTablet: isTablet,
    isPhone: isPhone,
    isAndroid: isAndroid,
    isPc: isPc,
  };
};

//正则
export const reg = {
  name: /^[\w\.\-]{5,24}$/, // eslint-disable-line
  pwd: /^\S{4,32}$/,
  email: /^\w+([\-+.]\w+)*@\w+([\-.]\w+)*\.\w+([\-.]\w+)*$/, // eslint-disable-line
  nickname: /^\S[^\r\n]{0,50}\S$/,
  question: /^\S[^\r\n]{0,80}\S$/,
  answer: /^\S[^\r\n]{0,80}\S$/,
  playlistname: /^\S[^\r\n]{0,50}\S$/,
};

// 打乱数组顺序
export function shuffle(arr) {
  let n = arr.length,
    random;
  while (0 !== n) {
    random = (Math.random() * n--) >>> 0; // 无符号右移位运算符向下取整
    [arr[n], arr[random]] = [arr[random], arr[n]]; // ES6的结构赋值实现变量互换
  }
  return arr;
}

// reUser = regexp.MustCompile(`^[\w\.\-]{5,24}$`)
// rePassword = regexp.MustCompile(`^\S{4,32}$`)
// reNickname = regexp.MustCompile(`^\S[^\r\n]{0,64}\S$`)
// reEmail = regexp.MustCompile(`^\w+([\-+.]\w+)*@\w+([\-.]\w+)*\.\w+([\-.]\w+)*$`)
// rePwdQuestion = regexp.MustCompile(`^\S[^\r\n]{0,125}\S$`)
// rePwdAnswer = regexp.MustCompile(`^\S[^\r\n]{0,125}\S$`)
// reSerial = regexp.MustCompile(`^[0-9]{16}$`)
// reNode = regexp.MustCompile(`^[0-9a-f]{32}$`)
// reNodeFind = regexp.MustCompile(`[0-9a-f]{32}`)
// reAlbumname = regexp.MustCompile(`^\S[^\r\n]{0,64}\S$`)
// rePlaylistname = regexp.MustCompile(`^\S[^\r\n]{0,64}\S$`)
