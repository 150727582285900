import login from './login';
import RecycleBin from './login/RecycleBin';
import photoalbum from './photoalbum/index';
import albumdetail from './photoalbum/albumdetail';
import rahmen from './frame/index';
import playlist from './playlist/index';
import playdetail from './playlist/playdetail'
import uploadFiles from './uploadFiles';
import feedback from './login/feedback';

const store = {
    login,
    photoalbum,
    albumdetail,
    rahmen,
    playlist,
    playdetail,
    uploadFiles,
    RecycleBin,
    feedback
}

export default store;