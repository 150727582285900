import React from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import { message } from 'antd';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import './App.scss';
import event from './util/event'
import PrivateRoute from './router/PrivateRoute';
import Index from './page/index';
import LoadableComponent from './common/Tool/LoadableComponent';
import Abnormal from './components/Abnormal/index'
import { observable } from 'mobx';

const Login = LoadableComponent(() => import('./page/Login'))
const Register = LoadableComponent(() => import('./page/Register'))
const Perfectinformation = LoadableComponent(() => import('./components/Perfectinformation'))

message.config({
  top: 100,
  maxCount: 1,
});



@withTranslation()
@inject('login', 'playlist', 'photoalbum')
@observer
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      connecting: true,
      successlconnection: false,
      disconnect: false
    }
  }
  @observable connect = null;


  componentDidMount() {
    const key = 'updatable';
    message.loading({ content: this.props.t('Connecting'), key, duration: 0 });
    event.on('wsinit', (msg) => {
      if (msg === 1) {
        message.success({ content: this.props.t('connection'), key, duration: 1 });
      }
      if (msg === 2) {
        message.error({ content: this.props.t('reconnecting'), key, duration: 0 });
      }
    })
  }

  render() {

    return (
      <div id='app'>
        <Router>
          <Switch>
            <Route exact path='/login' component={Login} />
            <Route exact path='/register' component={Register} />
            <PrivateRoute exact path='/perfectinformation/:id' component={Perfectinformation} />
            <PrivateRoute path='/' component={Index} />
            <Route component={Abnormal} />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
