import React from 'react';
import { message } from 'antd';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import ContentMain from '../../components/ContentMain';
import Uploadprogress from '../../components/Uploadprogress';
import Framebinddialog from '../../components/Framebinddialog/index';
import Createdbox from '../../common/Widget/Createdbox';
import { storage, removeCookie } from '../../util/index';
import event from '../../util/event';
import { buyUrl } from '../../util/url';
import Header from '../../components/Header';

@withTranslation() @inject('login', 'playlist', 'rahmen', 'photoalbum', 'uploadFiles') @observer
class Index extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
    this.state = {
      visible: false
    }
  }


  componentDidMount() {
    try {
      const { id, nickname, email, frames } = storage.get('user');
      if (!nickname || !email) {
        message.success(this.props.t('completeInfo'));
        this.props.history.push(`/perfectinformation/${id}`)
      }
      if (!frames) {
        this.props.rahmen.bindframevisibleshow();
        this.props.rahmen.fristbind();
      }
    } catch (error) { }

    event.on('isAutoLogin', (res) => {
      if (res) {
        // this.props.login.getUser_detail();
      } else {
        message.error(this.props.t("aotuLoginFailed"));
        storage.remove('user');
        removeCookie('lovtok');
        this.props.login.clearData();
        this.props.photoalbum.clearData();
        this.props.rahmen.clearData();
        this.props.playlist.clearData();
        this.props.uploadFiles.clearData();
        this.props.history.push(`/login`);
      }
    })
  }

  //新建播放列表确认
  cre_handleconfirm = (name) => {
    this.props.playlist.addpalylist(name);
  };


  render() {
    const { t } = this.props;
    const { bindframevisible, noframes } = this.props.rahmen;
    const { cre_visible, cre_handlecancel } = this.props.playlist;
    return (
      <div id='page'>
        <Header buyUrl={buyUrl} user={this.props.login.user_info} />

        <div className='page-content'>
          <ContentMain />
        </div>

        <Uploadprogress />

        {/* 绑定相框dialog */}
        {bindframevisible && <Framebinddialog noframes={noframes} />}

        {/* 新建播放列表dialog */}
        {cre_visible ? <Createdbox
          title={t('NewPlaylist')}
          handlecancel={cre_handlecancel}
          handleconfirm={this.cre_handleconfirm} /> : null}
      </div>
    );
  }
};

export default Index;
