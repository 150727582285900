import React from 'react'
import { Route, Redirect, } from 'react-router-dom';
import { storage, removeCookie } from '../../util/index';
import { message } from 'antd';
import i18next from 'i18next';
import login from '../../store/login';
import photoalbum from '../../store/photoalbum';
import rahmen from '../../store/frame';
import playlist from '../../store/playlist';
import uploadFiles from '../../store/uploadFiles';


function isLogin(name) {
  if (storage.get(name)) {
    return true;
  } else {
    if (localStorage.getItem('user') !== null) {
      message.error(i18next.t('aotuLoginFailed'))
    }
    storage.remove('user');
    removeCookie('lovtok');
    login.clearData();
    photoalbum.clearData();
    rahmen.clearData();
    playlist.clearData();
    uploadFiles.clearData();
    return false;
  }
}


const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    isLogin('user')
      // autoLogin()
      ? <Component {...props} />
      : <Redirect to={{
        pathname: '/login',
        state: { from: props.location }
      }} />
  )} />
)

export default PrivateRoute;