import { observable, action, set, remove } from 'mobx'
import { wsget } from '../../ws'
import { storage, reg } from '../../util';

const num = 536870912; //空间剩余阈值
// const num = 10737418000; //空间剩余阈值



class login {
  @observable OS = null; //全局的判断访问设备

  @observable isName = false;
  @observable check_name = false;
  @observable firstInit = false; //第一次加载用户信息
  @observable id_detail = {};
  @observable user_detail = {};
  @observable user_info = {}; //用户信息
  @observable unreadCount = 0; //未读消息
  token = ''

  //首页数据
  @observable user = {};
  @observable playlist = {};
  @observable album = {};
  @observable frame = {};
  @observable space = 0;


  //访问设备
  @action getos = (os) => {
    this.OS = os;
  }

  @action login_in = (data, cb) => {
    wsget('user_login', data).then((res) => {
      this.user_info = res;
      this.token = res.token;
      storage.set('user', { ...data, ...res });
      this.oldCount = this.user_info.unread;
      cb(res);
    }).catch(() => {
      cb(null);
    })
  }

  @action login_in2 = (data) => {
    this.oldCount = data.unread;
    for (let key in data) {
      set(this.user_info, key, data[key])
    }
  }


  @action user_login(data) {
    this.id_detail = data;
  }

  @action getUser_detail = (cb) => {
    wsget('user_detail', null).then((res) => {
      if (!res.unread) {
        remove(this.user_info, 'unread')
      }
      for (let key in res) {
        set(this.user_info, key, res[key])
      }
      cb && cb();
      this.firstInit = true;
    })
  }

  @action changeInfo = (key, data) => {
    set(this.user_info, key, data)
  }

  @action setRead = (num) => {
    set(this.user_info, 'unread', this.oldCount - num);
  }

  //注册部分
  @action reset() {
    this.check_name = false;
  }

  @action handleUsername(username) {
    if (!reg.name.test(username)) {
      this.isName = false;
      this.check_name = true;
      return;
    }
    wsget('user_check_name', username).then((res) => {
      this.isName = false;
      this.check_name = true;
    }).catch(() => {
      this.isName = true;
      this.check_name = true;
    })
  }

  @action info_login = (data, cb) => {
    wsget('user_detail_save', data, 1).then(() => {
      set(this.user_info, { nickname: data.nickname })
      set(this.user_info, { email: data.email })
      cb && cb();
    })
  }

  //检查邮箱是否存在
  @action checkEmail = (email) => {
    wsget('user_check_email', email).then((res) => {
      return true;
    }).catch((res) => {
      if (res.state === 2) {
        return false;
      }
    })
  }


  // //设置邮箱
  // @action setEmail = (email, cb) => {
  //   wsget('user_set_email', email, 1).then((res) => {
  //     this.getUser_detail(cb);
  //   })
  // }

  //首页
  @action getmain_profile = () => {
    wsget('main_profile', null).then((data) => {
      this.user = data.user;
      this.space = data.user.space_all - num;
      this.playlist = data.playlist;
      this.album = data.album;
      this.frame = data.frame;
      if (data.playlist.profile.length > 0) {
        let arr = data.playlist.profile;
        let leng = 4 - data.playlist.profile.length;
        if (leng !== 0) {
          for (let index = 0; index < leng; index++) {
            arr.push(-1)
          }
        }
        set(this.playlist, { profile: arr })
      } else {
        let arr = [];
        for (let index = 0; index < 4; index++) {
          arr.push(-1)
        }
        set(this.playlist, { profile: arr })
      }

      try {
        if (data.album.profile.length > 0) {
          let arr1 = data.album.profile;
          let leng = 4 - data.album.profile.length;
          if (leng !== 0) {
            for (let index = 0; index < leng; index++) {
              arr1.push(-1)
            }
          }
          set(this.album, { profile: arr1 })
        }
      } catch (error) {
        let arr1 = [];
        for (let index = 0; index < 4; index++) {
          arr1.push(-1)
        }
        set(this.album, { profile: arr1 })
      }

      try {
        let arr2 = data.frame.profile.length > 2 ? data.frame.profile.slice(0, 2) : data.frame.profile;
        set(this.frame, { profile: arr2 })

      } catch (error) {
        let arr2 = [];
        if (this.frame.count >= 2) {
          for (let index = 0; index < 2; index++) {
            arr2.push(-1)
          }
          set(this.frame, { profile: arr2 })
        } else {
          arr2.push(-1);
          set(this.frame, { profile: arr2 })
        }
      }
    }).catch((res) => {
      if (window.ws) {
        if (window.ws.isconnected() && res.state !== 0) {
          this.getmain_profile();
        }
      }
    })
  }
  @action addFrames = () => {
    set(this.frame, { count: this.frame.count + 1 })
  }
  @action addplaylist = () => {
    set(this.playlist, { count: this.playlist.count + 1 })
  }

  @action clearData = () => {
    this.OS = null; //全局的判断访问设备
    this.isName = false;
    this.check_name = false;
    this.firstInit = false; //第一次加载用户信息
    this.id_detail = {};
    this.user_detail = {};
    this.user_info = {}; //用户信息
    this.user = {};
    this.playlist = {};
    this.album = {};
    this.frame = {};
    this.space = 0;
  }
}


export default new login();
