import React from 'react';
import i18next from 'i18next';
import { Spin } from 'antd';
import './style.scss';

function Loading() {
  return (
    <div className="Loading-boby">
      <div className='loading-text'>{`${i18next.t('loading')}...`}</div>
      <Spin size='large' />
    </div>
  );
}

export default Loading;