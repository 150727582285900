import React, { Component } from "react";
import { notification, Icon, Modal, Drawer } from "antd";
import { withTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import "./style.scss";
import event from "../../util/event";
import { observable, values } from "mobx";
import i18next from "i18next";
// import { color } from "../../progresscolor";
import ListCell from "./ListCell";

const { confirm } = Modal;

@withTranslation()
@inject("uploadFiles")
@observer
class Uploadprogress extends Component {
  @observable winNum = 0;
  @observable failUpload = 0;
  @observable inner = 0;

  componentDidMount() {
    event.on("limit", (res) => {
      if (res > 0) {
        notification.warning({
          message: this.props.t("limit"),
          description: `${this.props.t("filtered")} ${res} ${this.props.t(
            "file"
          )}`,
        });
      }
    });
    this.resize();
    window.addEventListener("resize", this.resize);
  }

  componentWillUnmount() {
    event.rmName("UploadComplete");
    event.rmName("limit");
    window.removeEventListener("resize", this.resize);
  }

  resize = () => {
    if (window.innerWidth < 680) {
      if (!this.inner) {
        this.inner = true;
      }
    } else {
      if (this.inner) {
        this.inner = false;
      }
    }
  };

  close = () => {
    let arr = values(this.props.uploadFiles.files);
    if (arr.length > 0) {
      confirm({
        title: i18next.t("Discardupload"),
        content: i18next.t("unfinishedfiles"),
        okText: i18next.t("Determine"),
        okType: "danger",
        cancelText: i18next.t("Cancel"),
        onOk: () => {
          this.props.uploadFiles.abandon();
        },
        onCancel() {},
      });
    } else {
      this.props.uploadFiles.modalHide();
    }
  };

  small_close = () => {
    let arr = values(this.props.uploadFiles.files);
    if (arr.length > 0) {
      confirm({
        title: i18next.t("Discardupload"),
        content: i18next.t("unfinishedfiles"),
        okText: i18next.t("Determine"),
        okType: "danger",
        cancelText: i18next.t("Cancel"),
        onOk: () => {
          this.props.uploadFiles.abandon();
        },
        onCancel() {},
      });
    } else {
      this.props.uploadFiles.modalHide();
    }
  };

  render() {
    const { t } = this.props;
    const {
      visible,
      dis,
      is_dis,
      files,
      // winNum,
      // finish,
      // failNum,
      drawer_visible,
      showDrawer,
      onClose,
      small_dis,
    } = this.props.uploadFiles;

    if (this.inner) {
      return (
        <div id="small_progre" style={small_dis ? {} : { display: "none" }}>
          <div className="small_progre_l" onClick={showDrawer}>
            {`${t("Uploading")}, ${t('UPtotal').format(values(files).length)}`}
          </div>
          <div className="small_progre_r" onClick={this.small_close}>
            <Icon type="close" />
          </div>

          <Drawer
            placement="top"
            closable={false}
            onClose={onClose}
            visible={drawer_visible}
            bodyStyle={{ padding: 0 }}
          >
            <ul className="small_progre_box">
              {values(files).map((item) => {
                return (
                  <ListCell
                    key={item.id}
                    item={item}
                    deletelist={this.props.uploadFiles.delete}
                    retry={this.props.uploadFiles.retry}
                  />
                );
              })}
            </ul>
          </Drawer>
        </div>
      );
    } else {
      return (
        <div
          id="Uploadprogress"
          style={visible ? {} : { transform: "translateY(-110px)" }}
        >
          <div className="progress-header">
            <div className="progress-title">
              <p>
              {`${t("Uploading")}, ${t('UPtotal').format(values(files).length)}`}
              </p>
            </div>
            <div className="progress-operation">
              <Icon
                onClick={is_dis}
                style={dis ? { transform: "rotate(180deg)" } : {}}
                type="down"
              />
              <Icon onClick={this.close} type="close" />
            </div>
          </div>
          <div className={`progress-boby  ${dis && visible ? "block" : ""}`}>
            <div className="progress-list">
              <ul>
                {values(files).map((item) => {
                  return (
                    <ListCell
                      key={item.id}
                      item={item}
                      deletelist={this.props.uploadFiles.delete}
                      retry={this.props.uploadFiles.retry}
                    />
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Uploadprogress;
