import { set, remove, observable, action } from "mobx";
import dayjs from "dayjs";
import { wsget } from "../../ws";
import { fileSort, adaptation } from "../../util/index";

const minH = 285;
const minW = 240;

const minW2 = 230;

class photoalbum {
  albums = [];
  @observable firstInit = false; //第一次加载相册
  @observable albumList = []; //相册列表
  @observable removealbumList = []; //删除相册列表
  @observable albumdetailList = []; //相册详情列表
  @observable removeFileids = []; //删除文件列表
  @observable homealbumList = []; //...数据
  @observable auploadlist = []; //上传框选中列表
  urlData = {}; //跳转详情页的url数据

  //加载相册列表
  @action getPhotoalbum(sort, isdes, width) {
    this.width = width;
    wsget("album_list", null)
      .then((data) => {
        this.albums = data;
        // this.albumList = data;
        let newarr = this.sort(observable(data), sort, isdes);
        this.albumList = adaptation(width, newarr, minW, minH, minW2);
        this.firstInit = true;
      })
      .catch((res) => {
        if (window.ws) {
          if (window.ws.isconnected() && res.state !== 0) {
            this.getPhotoalbum(sort, isdes, width);
          }
        }
      });
  }

  @action resize = (width, arr) => {
    this.width = width;
    this.albumList = adaptation(width, arr, minW, minH, minW2);
  };

  //排序
  @action sort = (data, sort, isdes) => {
    if (isdes) {
      switch (sort) {
        case 0:
          //按更新时间排序
          return fileSort.update(data).reverse();
        case 1:
          //按创建时间排序
          return fileSort.create(data).reverse();
        case 2:
          //按照片数量排序
          return fileSort.filecount(data).reverse();
        case 3:
          //按文件名排序
          return fileSort.Filename(data).reverse();
        default:
      }
    } else {
      switch (sort) {
        case 0:
          //按更新时间排序
          return fileSort.update(data);
        case 1:
          //按创建时间排序
          return fileSort.create(data);
        case 2:
          //按照片数量排序
          return fileSort.filecount(data);
        case 3:
          //按文件名排序
          return fileSort.Filename(data);
        default:
          break;
      }
    }
  };

  //创建相册
  @action createAlbum(name, cb) {
    wsget("album_create", name, 1).then((data) => {
      const albmdata = {
        id: data,
        name,
        created: dayjs().format(),
        updated: dayjs().format(),
        filecount: 0,
      };
      if (this.albumList.length === 0) {
        this.albumList.unshift(albmdata);
        this.resize(this.width, this.albumList);
      } else {
        albmdata.w = this.albumList[0].w;
        albmdata.h = this.albumList[0].h;
        this.albumList.unshift(albmdata);
      }
      cb && cb();
    });
  }

  //删除相册
  @action album_delete = (id) => {
    if (id) {
      wsget("album_delete", id, 1).then(() => {
        this.removealbumList.remove(id[0]);
        this.albumList = this.albumList.filter((item) => item.id !== id[0]);
      });
    } else {
      wsget("album_delete", this.removealbumList, 1).then(() => {
        this.albumList = this.albumList.filter((item) => {
          return this.removealbumList.indexOf(item.id) === -1;
        });
        this.removealbumList = [];
      });
    }
  };

  //重命名相册
  @action renameAlbum = (name, id) => {
    wsget("album_rename", { id, name }, 1).then(() => {
      const i = this.albumList.findIndex((item) => item.id === id);
      this.albumList[i].name = name;
    });
  };

  //选中相册
  @action pitchOn = (e, id, cb1, cb2) => {
    e.stopPropagation();
    // cb2 && cb2();
    let pos = (this.albumList || []).findIndex((item) => item.id === id);
    if (pos > -1 && this.albumList[pos].check) {
      remove(this.albumList[pos], "check");
      this.removealbumList.remove(id);
      cb2 && cb2();
    } else {
      set(this.albumList[pos], { check: true });
      this.removealbumList.push(id);
      if (this.albumList.length === this.removealbumList.length) {
        cb1 && cb1();
      }
    }
  };

  //全选相册列表
  @action checkAllalbumlist() {
    if (this.removealbumList.length !== this.albumList.length) {
      for (let i = 0; i < this.albumList.length; i++) {
        if (!this.albumList[i].check) {
          set(this.albumList[i], { check: true });
          this.removealbumList.push(this.albumList[i].id);
        }
      }
    } else {
      for (let i = 0; i < this.albumList.length; i++) {
        remove(this.albumList[i], "check");
      }
      this.removealbumList = [];
    }
  }

  //取消选择
  @action cancelAll = () => {
    for (let i = 0; i < this.albumList.length; i++) {
      remove(this.albumList[i], "check");
    }
    this.removealbumList = [];
  };

  //反选
  @action invert = () => {
    this.removealbumList = [];
    this.albumList.forEach((el) => {
      if (el.check) {
        remove(el, "check");
        this.removealbumList.remove(el.id);
      } else {
        set(el, { check: true });
        this.removealbumList.push(el.id);
      }
    });
  };

  //相册列表排序
  @action album_sort(i, isdes) {
    this.albumList = this.sort(this.albumList, i, isdes);
  }

  //清除重置
  @action replacement = () => {
    this.albumList.forEach((el) => {
      remove(el, "check");
    });
    this.removealbumList = [];
  };

  //跳转详情页的url数据存储
  @action goUrl = (obj) => {
    this.urlData = obj;
  };

  @action clearData = () => {
    this.firstInit = false; //第一次加载相册
    this.albumList = []; //相册列表
    this.removealbumList = []; //删除相册列表
    this.albumdetailList = []; //相册详情列表
    this.removeFileids = []; //删除文件列表
    this.homealbumList = []; //...数据
    this.auploadlist = []; //上传框选中列表
    this.urlData = {}; //跳转详情页的url数据
  };
}

export default new photoalbum();
