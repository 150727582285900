import React, { PureComponent } from "react";
import { Icon, Tooltip } from "antd";
import dayjs from "dayjs";
import { withTranslation } from "react-i18next";
import { getFile } from "../util/index";
import "./index.scss";

@withTranslation()
class SmallPlaylist extends PureComponent {
  state = {};
  render() {
    const { t, listW, pitchOn, item, check, checklist } = this.props;
    return (
      <div className="list_BOX" style={{ width: listW }}>
        <div
          className={`list-box ${
            checklist
              ? item.id === checklist.id
                ? "list-checked"
                : ""
              : check
              ? "list-checked"
              : ""
          }`}
          onClick={() => {
            pitchOn(item);
          }}
        >
          <span className="list-box-check">
            <Icon type="check" />
          </span>
          <div className="top-img" style={{ height: listW * 0.58 }}>
            <div className="img1">
              {item.profile && (
                <img
                  alt={item.profile.key}
                  src={getFile.file_s(item.profile.id)}
                />
              )}
            </div>
            <div className="img2-4">
              <span className="img2">
                {item.profile_1 && (
                  <img
                    alt={item.profile_1.key}
                    src={getFile.file_s(item.profile_1.id)}
                  />
                )}
              </span>
              <span className="img3">
                {item.profile_2 && (
                  <img
                    alt={item.profile_2.key}
                    src={getFile.file_s(item.profile_2.id)}
                  />
                )}
              </span>
              <span className="img4">
                {item.profile_3 && (
                  <img
                    alt={item.profile_3.key}
                    src={getFile.file_s(item.profile_3.id)}
                  />
                )}
              </span>
            </div>
          </div>
          <div className="bottom-text">
            <Tooltip
              placement="bottom"
              overlayClassName="tooltip"
              title={item.name}
            >
              <p className="playlist-name">{item.name}</p>
            </Tooltip>
            <p className="update-time">
              <span>
                {`${t("LastUpdate")}:`}
                {dayjs(item.updated).format(t("L"))}
              </span>
            </p>
            <span className="playlist-photo-num">
              {t("Photo")}:{item.filecount ? item.filecount : 0}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default SmallPlaylist;
