// 建立一个连接到websocket的长连接，并且负责重连的功能
// 这个协议文件为标准

export const MINI_SYSTEM_ERROR = 100;
export const ERROR_RESPONSE = 123;
export const GET_TIME_ERROR = 124;
export const GET_TIMEOUT = 125;
export const NO_CONNECT = 126;
export const NO_MATCH = 127;

const defaultConnectTime = 10000; // 建立新的连接超时时间
const defaultTimeout = 30000; // 默认超时时间（毫秒）

class WsMQ {
  // path -- 连接的路径
  // hostfun -- 获取一个连接服务节点
  // openfun -- (Option)成功连接调用的方法
  // closefun -- (Option)连接断开时调用
  constructor(path, hostfun, openfun, closefun) {
    // openfun 是当连接成功后的回调函数
    const ts = this;
    ts.path = path;
    ts.hostfun = hostfun;
    ts.id = Math.floor(Math.random() * 9999) + 1;
    ts.last = 0; // 用于定时发送ping包保持连接，并用于指示是否连接成功
    ts.conn = null;
    ts.openfun = openfun || (async () => {});
    ts.closefun = closefun || (async () => {});
    ts.cmds = {}; // 保存请求的id，请求时间和回调函数, 回调函数为 (state, data)
    ts.registers = {}; // 保存注册的回调函数，回调函数为（cmd, data, backfun）
    ts.willsend = []; // 保存连接过程中等待发送的消息
    ts.timer = {}; // 记录发送数据的计数器
    // 建立连接
    ts.connect();

    // 建立一个定时器，判断连接异常的时候重连
    setInterval(() => {
      if (!ts.conn) {
        ts.connect();
        return;
      }
      const t = new Date().getTime();
      if (ts.last === 0) {
        return;
      }
      if (t - ts.last > 60000) {
        // 连接有问题了，重连
        ts.close();
        //ts.conn = null;
        ts.last = 0;
        ts.reconnect(true, Math.floor(Math.random() * 2000) + 1000);
        return;
      }
      if (t - ts.last > 40000) {
        // 发送拼包
        ts.send({ id: ts.getid() });
      }
    }, 30000);
  }

  getid() {
    if (this.id <= 0) {
      this.id = 1;
    } else {
      this.id += 1;
    }
    return this.id;
  }

  send(data) {
    if (this.last > 0 && this.conn) {
      try {
        this.conn.send(JSON.stringify(data));
      } catch (er) {
        this.willsend.push(data);
      }
    } else {
      this.willsend.push(data);
    }
  }

  // 清理当前已经发送命令，返回 NO_CONNECT 状态
  cleanCmd() {
    const ts = this;
    for (var t in ts.timer) {
      clearTimeout(ts.timer[t]);
    }
    ts.timer = {};
    for (var cmd in ts.cmds) {
      ts.cmds[cmd](NO_CONNECT);
    }
    ts.cmds = {};
  }

  // 建立连接
  // 如果host为true表示强制获取新的主机
  async connect(host) {
    const ts = this;
    if (ts.conn) {
      // 避免频繁重连
      return;
    }
    // 检查最近进来的时间
    const lastTime = +new Date();
    if (ts.lastConnectTime && lastTime - ts.lastConnectTime < 15000) {
      return;
    }
    ts.lastConnectTime = lastTime;
    if (!host) {
      host = await ts.hostfun();
    } else if (host === true) {
      host = await ts.hostfun(true);
    }
    if (!host) {
      // 没有服务器数据，重新获取
      setTimeout(() => {
        ts.connect();
      }, 1000);
      return;
    }
    if (host.port) {
      ts.port = host.port;
    } else {
      const ps = host.https_ports.split("|");
      ts.port = ps[Math.floor(Math.random() * ps.length)]; // 随机选择一个端口
    }
    ts.host = host;
    // 由于在浏览器上有些端口不能用，这里做循环处理
    for (let i = 0; i < 9; i++) {
      try {
        const url = `wss://${host.domain}:${ts.port}${
          typeof ts.path === "function" ? ts.path() : ts.path
        }`;
        ts.conn = new WebSocket(url); // 随机产生一个url地址并建立连接
        host.port = ts.port;
        break;
      } catch (ex) {
        const ps = host.https_ports.split("|");
        ts.port = ps[Math.floor(Math.random() * ps.length)]; // 随机选择一个端口
      }
    }
    if (!ts.conn) {
      return;
    }
    // 设置一个定时器检测连接超时，默认为10秒
    ts.connectTimer = setTimeout(() => {
      ts.connectTimer = null;
      ts.close();
    }, defaultConnectTime);
    ts.conn.onerror = (ev) => {
      console.log("[WS Error]", ev);
      ts.conn = null;
      ts.last = 0;
      ts.reconnect(true, Math.floor(Math.random() * 5000) + 10000);
      ts.cleanCmd();
    };
    ts.conn.onclose = () => {
      console.log("[WS Close]", ts.host);
      ts.conn = null;
      ts.last = 0;
      ts.closefun(ts.host);
      ts.reconnect(null, Math.floor(Math.random() * 5000) + 5000);
      ts.cleanCmd();
    };
    ts.conn.onmessage = (evt) => {
      ts.last = new Date().getTime();
      try {
        const msg = JSON.parse(evt.data);
        if (msg.cmd) {
          // 请求的数据包
          console.log("---WS-PUSH---", msg);
          const backfun = ts.registers[msg.cmd];
          if (backfun) {
            backfun(msg.data, (state, result) => {
              // 如果msg.id 为0 表示不需要回应消息。
              if (msg.id === 0) {
                return;
              }
              const dx = {
                id: msg.id,
                state,
              };
              if (result !== null && result !== undefined) {
                dx.data = result;
              }
              ts.send(dx);
            });
          } else if (msg.id !== 0) {
            ts.send({
              id: msg.id,
              state: NO_MATCH,
            });
          }
        } else if ("state" in msg) {
          // 响应数据包
          const tcmd = ts.cmds[msg.id];
          if (tcmd) {
            delete ts.cmds[msg.id];
            if (ts.timer[msg.id]) {
              clearTimeout(ts.timer[msg.id]);
              delete ts.timer[msg.id];
            }
            tcmd(msg.state, msg.data);
          }
        }
      } catch (ex) {
        console.log(ex);
        return;
      }
    };
    // 连接成功的回调
    ts.conn.onopen = () => {
      // 清除定时器
      if (ts.connectTimer) {
        clearTimeout(ts.connectTimer);
        ts.connectTimer = null;
      }
      ts.last = new Date().getTime();
      ts.openfun(ts.host).then(() => {
        ts.willsend.forEach((data) => {
          ts.send(data);
        });
        ts.willsend = [];
      });
    };
  }

  // 注册监听函数，(string, function(data, (state, result)=>{}))
  register(cmd, backfun) {
    this.registers[cmd] = backfun;
  }

  // 获取服务器数据，如果backfun为空表示不需要返回数据
  get(cmd, data, backfun, timeout = defaultTimeout) {
    const ts = this;
    if (!ts.conn || ts.conn.readyState !== WebSocket.OPEN) {
      if (backfun) {
        backfun(NO_CONNECT);
      }
      return;
    }
    let id = 0;
    if (backfun && timeout > 0) {
      id = ts.getid();
      ts.cmds[id] = backfun;
      // 定时删除没有响应的命令
      ts.timer[id] = setTimeout(() => {
        delete ts.timer[id];
        const bf = ts.cmds[id];
        if (bf) {
          delete ts.cmds[id];
          bf(GET_TIMEOUT);
        }
      }, timeout);
    }
    const dx = { id, cmd };
    if (data !== null && data !== undefined) {
      dx.data = data;
    }
    ts.send(dx);
  }

  // 获取连接的主机节点
  geturl(pre = "https") {
    const ts = this;
    return ts.conn && ts.host ? `${pre}://${ts.host.domain}:${ts.port}` : "";
  }

  // 更新路径
  setpath(path, delay = 1) {
    const ts = this;
    ts.path = path;
    ts.close();
    setTimeout(() => {
      ts.connect(ts.host);
    }, delay);
  }

  // 重新连接，指定连接到那个服务节点
  // host 可以为空
  reconnect(host, delay = 100) {
    const ts = this;
    if (!host && ts.conn) {
      return;
    }
    ts.close();
    setTimeout(() => {
      ts.connect(host);
    }, delay);
  }

  // 关闭连接
  close() {
    const ts = this;
    if (ts.conn) {
      ts.conn.close();
      //ts.conn = null;
    }
    ts.last = 0;
    ts.cleanCmd();
  }

  // 是否已经连接
  isconnected() {
    return !!this.conn && this.last > 0;
  }
}

export default WsMQ;
