export default {
  key: "en",
  dayjskey: "en",
  name: "English",
  alias: "en,en-US,en-GB",
  homepage: "Home",
  playlist: "Playlists",
  photoframe: "Frames",
  cloudalbum: "Albums",
  Welcometouse: "Welcome to use {0}",
  Multiframemanagement: "Multi-frame management",
  Onebuttonsynchronization: "One click sync",
  Emailisendphotos: "Email send photos",
  Cloudspace: "Cloud space",
  Upgradestorage: "Upgrade storage space",
  Upgradestorage2: "Insufficient storage space",
  Alreadyused: "Already used",
  DownloadMobileApp: "Download Mobile App",
  appexplain: "Download {0} Mobile APP, manage digital frames and upload photos conveniently on Android and iOS devices!",
  Emailconfiguration: "Email configuration",
  emailexplain1: "Send photos to your by email",
  emailexplain2: " email of receiving photos ",
  emailexplain3: "to quickly sync photos to digital frame!",
  receiveemail: "Your email of receiving photos is:",
  Configureauthorizationmailbox: "Configure authorized mailbox",
  L: "DD/MMM/YYYY",
  LL: "DD/MMM/YYYY HH:mm",
  updateSort: "Sort by updated time",
  creationSort: "Sort by created time",
  photosSort: "Sort by photo quantity",
  nameSort: "Sort by name",
  bindingSort: "Sort by paired time",
  Determine: "Confirm",
  Cancel: "Cancel",
  Allelection: "Select all",
  delete: "Delete",
  rename: "Rename",
  Deleteconfirm: "Delete confirmation",
  list: "playlists",
  Admin: "Admin",
  enterName: "Please enter playlist name",
  loading: "loading...",
  comeNothing: "Failed, please try again",
  successful: "Operate successfully",
  WaitingValidation: "Waiting for validation",
  confirmBinding: "Please confirm on the frame",
  ValidationFailed: "Validation failed",
  tryAgain: "Please try again",
  notOnline: "Digital frame is not online. Please make sure the frame is powered on and connected to the Internet",
  BindingSuccess: "Pairing Successfully",
  BindingFrame: "Pairing Frame",
  cancelBinding: "Canceling the operation now will lead to failure of pairing. Do you want to cancel the operation?",
  DefaultPlaylist: "Select a default playlist",
  NewPlaylist: "New playlist",
  serial: "Please enter the correct format of frame serial number",
  enterSerial: "Please enter the frame serial number",
  frameName: "Please set the frame name",
  getSerial: "How to get the frame serial number:",
  action: "Frame Settings > About > Frame serial number",
  NoList: "No playlist selected",
  setFrameName: "Please enter the setting frame name",
  selectList: "Name or default playlist is empty",
  Screen: "Filtrate",
  nameList: "Set name and select default playlist",
  ModifynameList: "Modify name and default playlist",
  Framealreadybound: "Frame already paired",
  Noselected: "No playlist selected",
  Listnamedoes: "Playlist name does not conforms with the regulations",
  Photo: "Photos",
  Surplusspace: "Remaining space",
  Photoframe: "Digital frame",
  LastUpdate: "Last update",
  default: "default",
  SetDefaultPlaylist: "Set as default playlist",
  SelecList: "Select playlist",
  storageSpace: "storage space",
  bindingTime: "Pairing time",
  UserList: "User list",
  cancelAdmin: "cancel admin",
  setAdmin: "set as admin",
  modifyNickname: "modify nickname",
  Close: "Close",
  defaultNotDelete: "Deletion of default playlist is not allowed",
  albumAdd: "Add from albums",
  Addplaylist: "Add from playlists",
  CreationTime: "Created time",
  deleteTheSelected: "Confirm to delete the selected ",
  modalWarning: "All photos selected ",
  CarefulOperation: " will be deleted. Please be careful.",
  document: "file",
  firstOne: "The first one",
  lastOne: "The last one",
  Deletedefault: "If you want to delete it, please set the selected playlist not to be a default playlist of the digital frame, or you can delete the paired digital frame directly",
  NewAlbum: "New album",
  photoAlbum: "album",
  accountmanagement: "account information",
  Buyingphotoframes: "Buy a frame",
  Personalinformation: "Personal Information",
  ChangetheAvatar: "Change avatar",
  Nickname: "Nickname",
  SetNickname: "Set nickname",
  accountinformation: "Account Information",
  mailbox: "Mailbox",
  Changingmailbox: "Change mailbox",
  Password: "Password",
  Changepassword: "Change password",
  Thirdpartyaccountbinding: "Binding third party account",
  account: "Account",
  Logout: "Log out",
  ChangeNickname: "Change nickname",
  enterEmail: "Please enter email",
  ChangePwd: "Change password",
  Oldpwd: "Old password",
  enterOldpwd: "Please enter the old password",
  Preservation: "Preservation",
  aotuLoginFailed: "Login failed, please log in again",
  Connecting: "Connecting to server...",
  connection: "Successful connection",
  reconnecting: "Communication with server is broken, reconnecting…",
  entertwoPwds: "Please enter a duplicate password",
  Signsmartframe: "Log in your smart frame",
  Registeredaccountnumber: "Create an account",
  login: "Log in",
  nameMailbox: "User name / mailbox",
  ForgetPwd: "Forget the password?",
  Registered: "Create an account",
  enterNameMailbox: "Please enter user name / email",
  PreviousStep: "Previous step",
  NextStep: "Next step",
  UserName: "User name",
  Problem: "Question",
  Answer: "Answer",
  NewPwd: "New password",
  enterNewpwd: "Please enter a new password",
  ConfirmPwd: "Confirm the password",
  enterPwdAgain: "Please enter the password again",
  ResetPwd: "Reset password",
  enterAnswer: "Please enter the answer",
  WelcomeRegister: "Welcome to registration center",
  haveAccount: "Do you already have an account?",
  setUserName: "Please set a user name",
  setPassword: "Please set a password",
  RepeatPwd: "Repeat password",
  Vcode: "Verification Code",
  enterVcode: "Verification code",
  InRegistration: "Registering",
  Register: "Register",
  meansConsent: "By registering we agree to",
  ServiceAgreement: "Service agreement",
  PrivacyPolicy: "Privacy policy",
  and: "and",
  frameservice: "Smart frame service protocol",
  CaseInsensitive: "Case insensitive",
  changeItcode: "Not clear? Click on the picture to change to another one",
  AccountPwdEmpty: "Account or password cannot be empty",
  accountFormatWrong: "Wrong account format",
  accountExists: "The account already exists, please change to another one",
  twoPwds: "The two passwords are not same at all, please enter again",
  codeErr: "Verification code input is error",
  SuccesCompleteInfo: "Registering successfully, please complete the personal information",
  completeInfo: "Please complete the personal information",
  LoginSucceed: "Login successfully!",
  LoginFailed: "Login failed! Account name or password is wrong",
  PerfectInfo: "Perfect information",
  HeadPortrait: "Avatar",
  ChangeAvatar: "Change the avatar",
  SetMailbox: "Set mailbox",
  securityQuestion: "Question",
  enterQuestion: "Please enter a question",
  CustomQuestions: "Custom questions",
  required: " with * is must!",
  birthday: "When's your birthday?",
  yourmind: "Who's the hero in your mind?",
  favoriteperson: "Who's your favorite person?",
  selectQuestion: "Please check the validity of the question",
  problemValidity: "Please check the validity of the problem",
  NicknameEmpty: "Please input your nickname!",
  MailboxEmpty: "Please input your E-mail!",
  nicknameValidity: "Please check the validity of the nickname",
  emailValidity: "Please check the validity of the email",
  answerValidity: "Please check the validity of the answer",
  pwdFormat: "Password format is error",
  Calibration: "Verifying…",
  isCorrect: "Please check whether the user name or mailbox is correct or not?",
  setnewPwd: "Please set a new password",
  WrongAnswer: "Wrong answer",
  oldpwdinc: "The old password is incorrect",
  oldnewsame: "The old and new passwords are the same, no need to change",
  regrule1: "from 5 to 24 English alphabets or numeric characters in length.",
  regrule2: "from 4 to 32 characters in length.",
  regrule3: "No spaces & Chinese characters included",
  regrule4: "No special characters included",
  regrule5: "Please conforms with the regulations of email account name",
  regrule6: "More than two characters, no special characters included",
  regrule7: "The security question must not be less than two characters",
  regrule8: "Answer cannot be less than two characters",
  boundmail: "The mailbox has been bound. Please change it",
  Uploadphotosto: "Upload photos to",
  Pleaseseupload: "Please select the location to upload",
  Uploadphotos: "Upload photos",
  Uploadto: "Upload to",
  modifylocation: "change location",
  Uploadmode: "Upload mode",
  Uploadfolder: "Upload folder",
  Uploading: "Uploading",
  Upload: "Upload",
  items: "items",
  About: "About",
  minuteleft: "minutes left",
  Cancelall: "Cancel all",
  limit: "The file size exceeds the limit",
  filtered: "filtered",
  file: "photos",
  Headimage: "Setting Avatar",
  uploadavatar: "Select local photos, upload and edit your own avatar",
  Choosepicture: "Choose a picture",
  SupportPic: "Support JPG, JPEG, PNG, BMP format pictures (picture maximum size is 3MB)",
  Picsize: "Picture size exceeds the limit",
  imagePreview: "Preview",
  picsizeMore: "Picture size less than 10MB",
  VsizeMore: "Video size less than 50MB",
  adddescription: "Click to add a description",
  enterdescription: "Please enter a description",
  AddCpt: "Add a description",
  Selected: "Selected",
  Reverseselection: "Reverse",
  Movefront: "Move to front",
  Movelast: "Move to last",
  Chooseframe: "Select frames",
  playtoframe: "Click to play in frames",
  Albumadd: "Add photos in album",
  Listempty: "Playlist is empty",
  Noselectedpic: "No picture selected",
  Replacecover: "Replace the cover",
  control: "Remote control",
  Responding: "In the response",
  Framenotonline: "Frame not online",
  copyright: "Copyright © {0}. All Rights Reserved. V{1}",
  Discardupload: "Discard upload",
  unfinishedfiles: "There are some files still in uploading. Are you sure to give up the process?",
  Successuploaded: "Successfully",
  fail: "fail",
  Functionnotopen: "Function is not available",
  retry: "retry",
  waiting: "waiting",
  uploading2: "uploading",
  pwdreg: "Within 1-50 characters, without spaces",
  enterset: "Please enter a new password",
  picturemaximum: "picture maximum 10M",
  Notmorechar: "Not more than 100 characters",
  Login_in: "Login...",
  loginout: "Do you want to exit the login?",
  Registrationfailed: "Registration failed",
  detailedinformation: "Detailed information",
  Uploadcompleted: "Completed",
  frame: "frame",
  Videofailed: "Video loading failed",
  framebindfirst: "Hello, Please bind the frame first",
  temporaryFiles: "Trash",
  Cleanphotos: "Clear photos",
  addto: "Add to",
  Selected2: "Selected({0})",
  ChangeTheAccount: "Change the account",
  Feedback: "Feedback",
  Language: "Language ({0})",
  removeWare_T: "Are you sure to delete the selected image or video?",
  removeWare_C: "Deleted images and videos can be recovered at the recycle bin",
  NotMailbox: "Not yet bound to a mailbox",
  UploadToList: "Upload to the current list",
  FeedbackHistory: "Feedback history",
  addFeedback: "Click to add feedback",
  me: "Me",
  reply: "Reply",
  ProblemFeedback: "Problem feedback",
  Submit: "Submit",
  enterTitle: "Please enter question title",
  enterDdescription: "Please enter the problem description",
  Upto10: "Up to 10 images",
  feebfaid: "Image upload failed, please try again",
  feedWarning1: "Title cannot be empty",
  feedWarning2: "Description cannot be empty",
  feedWarning3: "The picture is still being uploaded",
  UPtotal: "total of {0}",
  fileUpBig: "File is too large",
  notSupported: "Upload not supported",
  Invalidpicture: "Invalid file",
  fileUpFailed: "Upload failed",
  pic: "",
  set: "New password",
}
