import { observable, action, set, remove } from "mobx";
import { wsget } from "../../ws";
import { message } from "antd";
import i18next from "i18next";
import { imgLoad, getFile } from "../../util/index";
import event from "../../util/event";
import dayjs from "dayjs";

class albumdetail {
  @observable init = false; //加载
  @observable albumdetail = []; //相册详情列表
  @observable removeFileids = []; //选中的图片列表
  @observable columnCount = null; //行数
  @observable filenum = 0; //文件数量
  @observable visible = false; //大图modul显隐
  @observable imgfile = {}; //大图文件
  @observable imgidx = null; //切换图片的标识
  @observable updated = null; //更新时间

  //获取数据
  @action getData = (id, getWH, getH) => {
    this.init = false;
    this.id = id;
    this.close = false;
    this.getH = getH;
    this.getWH = getWH;
    wsget("album_detail_mini", id).then((data) => {
      this.filenum = data.length;
      this.columnCount = getWH();
      this.pushData(data);
      getH();
      if (!this.close) {
        this.init = true;
      }

      if (!this.albumupload) {
        this.albumupload = event.on("albumupload", (res) => {
          if (id === res.id) {
            const item = this.albumdetail.find(
              (el) => el.id === res.list[0].id
            );
            if (item) {
              delete item.check;
              delete item.completed;
              this.albumdetail.remove(item);
            } else {
              this.filenum += 1;
            }
            this.albumdetail.unshift(...res.list);
            let newArr = this.albumdetail.filter((item) => {
              return !item.true;
            });
            this.pushData(newArr);
            getH();
            this.resize(this.getWH);
          }
        });
      }
    });
  };

  @action update = (date) => {
    this.updated = date;
  };

  //选中图片
  @action checkclick = (e, item) => {
    e.stopPropagation();
    if (this.removeFileids.length > this.filenum) {
      this.removeFileids = this.removeFileids.filter((item) => {
        return !item.true;
      });
    }
    if (item.check) {
      remove(item, "check");
      this.removeFileids.remove(item.id);
    } else {
      set(item, { check: true });
      this.removeFileids.push(item.id);
    }
    if (this.removeFileids.length === this.filenum) {
      this.albumdetail.forEach((el) => {
        if (el.true) {
          this.removeFileids.push(el);
        }
      });
    }
  };

  //全选
  @action oncheckAll = () => {
    if (this.albumdetail.length !== this.removeFileids.length) {
      this.removeFileids = [];
      this.albumdetail.forEach((el) => {
        if (!el.id) {
          this.removeFileids.push({ true: "true" });
        } else {
          this.removeFileids.push(el.id);
        }
        set(el, { check: true });
      });
    } else {
      this.albumdetail.forEach((el) => {
        remove(el, "check");
      });
      this.removeFileids = [];
    }
  };

  //取消选择
  @action cancelAll = () => {
    this.albumdetail.forEach((el) => {
      remove(el, "check");
    });
    this.removeFileids = [];
  };

  //删除图片
  @action remove_img = () => {
    let arr = this.removeFileids.filter((item) => !item.true);
    let data = {
      id: this.id,
      files: arr,
    };
    wsget("album_delete_files", data, 1).then(() => {
      arr.forEach((item) => {
        const data = this.albumdetail.find((el) => el.id === item);
        delete data.check;
        delete data.completed;
        this.albumdetail.remove(data);
        this.filenum -= 1;
      });
      let newArr = this.albumdetail.filter((item) => {
        return !item.true;
      });
      this.pushData(newArr);
      this.getH();
      this.resize(this.getWH);
      this.updated = dayjs().format();
      this.removeFileids = [];
    });
  };

  //反选
  @action invert = () => {
    this.removeFileids = [];
    this.albumdetail.forEach((el) => {
      if (!el.true) {
        if (el.check) {
          remove(el, "check");
          this.removeFileids.remove(el.id);
        } else {
          set(el, { check: true });
          this.removeFileids.push(el.id);
        }
      }
    });
  };

  //打开图片查看
  @action b_img_show = (i) => {
    const reg = /(avi|mov|rmvb|rm|flv|mp4|3gb)$/;
    this.visible = true;
    this.imgfile = this.albumdetail[i];
    this.imgidx = i;
    if (!reg.test(this.albumdetail[i].ext)) {
      if (i + 1 < this.filenum) {
        if (!reg.test(this.albumdetail[i + 1].ext)) {
          imgLoad(getFile.file_b(this.albumdetail[i + 1].id));
        }
      }
      if (!(i - 1 < 0)) {
        if(!reg.test(this.albumdetail[i - 1].ext)){
          imgLoad(getFile.file_b(this.albumdetail[i - 1].id));
        }
      }
    } else {
      if (i + 1 < this.filenum) {
        if (!reg.test(this.albumdetail[i + 1].ext)) {
          imgLoad(getFile.file_b(this.albumdetail[i + 1].id));
        }
      }
      if (!(i - 1 < 0)) {
        if(!reg.test(this.albumdetail[i - 1].ext)){
          imgLoad(getFile.file_b(this.albumdetail[i - 1].id));
        }
      }
    }
  };

  //关闭大图
  @action closeDialog = () => {
    this.visible = false;
  };
  //上一张
  @action onPiece = () => {
    if (this.imgidx - 1 >= 0) {
      this.b_img_show(this.imgidx - 1);
    } else {
      this.imgidx = this.filenum - 1;
      this.b_img_show(this.imgidx);
      message.warning(i18next.t("firstOne"));
    }
  };
  //下一张
  @action nextPage = () => {
    if (this.imgidx + 1 >= this.filenum) {
      this.imgidx = 0;
      this.b_img_show(this.imgidx);
      message.warning(i18next.t("lastOne"));
      return;
    }
    this.b_img_show(this.imgidx + 1);
  };

  //添加描述
  @action addCpt = (cpt) => {
    const { id } = this;
    let data;
    if (cpt !== "") {
      data = {
        id,
        list: [{ id: this.albumdetail[this.imgidx].id, cpt }],
      };
    } else {
      data = {
        id,
        list: [{ id: this.albumdetail[this.imgidx].id }],
      };
    }
    wsget("album_add_files", data, 1).then(() => {
      wsget("album_detail_mini", id).then((data) => {
        this.updated = dayjs().format();
        this.filenum = data.length;
        this.pushData(data);
        set(this.imgfile, { cpt });
      });
    });
  };

  @action pushData = (list) => {
    if (list.length % this.columnCount !== 0) {
      this.fornum = this.columnCount - (list.length % this.columnCount);
      for (let index = 0; index < this.fornum; index++) {
        list.push({ true: true });
      }
    }
    this.albumdetail = list;
  };

  @action resize = (cb) => {
    let newArr = this.albumdetail.filter((item) => {
      return !item.true;
    });
    this.columnCount = cb();
    this.pushData(newArr);
    this.getH();
  };

  @action clearData = () => {
    this.close = true;
    this.init = false;
    this.removeFileids = [];
    event.rm(this.albumupload);
    this.albumupload = null;
  };
}

export default new albumdetail();
