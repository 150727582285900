import React from "react";
import { get } from "mobx";
import { Icon, Progress } from "antd";
import { withTranslation } from "react-i18next";
import { color } from "../../progresscolor";
import { observer } from "mobx-react";

@withTranslation()
@observer
class ListCell extends React.Component {
  state = {};

  TextInfo = (item) => {
    const { t } = this.props;
    if (item.toBig) {
      return <span className="hashErr">{t("fileUpBig")}</span>;
    }
    if (item.typeErr) {
      return <span className="hashErr">{t("notSupported")}</span>;
    }
    if (item.invalid) {
      return <span className="hashErr">{t("Invalidpicture")}</span>;
    }
    if (item.failed) {
      return <span className="hashErr">{t("fileUpFailed")}</span>;
    }
  };

  render() {
    const { t, item, deletelist, retry } = this.props;
    return (
      <li className="up_list_cell">
        <Icon type="picture" theme="filled" />
        <span className="filename">{item.name}</span>
        <div className="right">
          {get(item, "delet") && (
            <Icon
              onClick={() => {
                deletelist(item);
              }}
              className="cancelup"
              type="close"
            />
          )}

          {get(item, "wrong") ? (
            this.TextInfo(item)
          ) : (
            <span className="awaitUp">
              {get(item, "awite")
                ? `${t("uploading2")}···`
                : `${t("waiting")}···`}
            </span>
          )}

          {get(item, "retry") && (
            <span
              className="retry"
              onClick={() => {
                retry(item);
              }}
            >
              {t("retry")}
            </span>
          )}

          <Progress
            className="list-progress"
            trailColor="#C5C5C5"
            strokeColor={color}
            strokeWidth={17}
            showInfo={false}
            type="circle"
            percent={item.percent}
            width={24}
          />
        </div>
      </li>
    );
  }
}

export default ListCell;
