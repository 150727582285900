import React, { Component } from 'react';
import { Icon,message } from 'antd';
import { withTranslation } from 'react-i18next';
import './style.scss'
import { trim,reg } from '../../util/index';

@withTranslation()
class Createdbox extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    this.inputRef.current.focus();
  }

  handleOK = () => {
    let val = trim(this.inputRef.current.value);
    if (!val) {
      message.error(this.props.t('enterName'));
      return;
    }else if(!reg.playlistname.test(val)){
      message.error(this.props.t('Listnamedoes'));
      return;
    }
    this.props.handleconfirm(val);
  }

  render() {
    const { t, title, handlecancel } = this.props;
    return (
      <div className='custom-modal'>
        <div className='modal-warp'>
          <div className='modal-warp-header'>
            <h3>{title}</h3>
            <span onClick={handlecancel}>
              <Icon type="close" />
            </span>
          </div>
          <div className='modal-warp-boby'>
            <div className='modal-warp-input'>
              <input ref={this.inputRef} maxLength={50} spellCheck={false} type="text" placeholder={t('enterName')} />
            </div>
            <div className='modal-warp-handle'>
              <span className='cancel-btn' onClick={handlecancel}>{t('Cancel')}</span>
              <span
                className='confirm-btn'
                onClick={this.handleOK}>{t('Determine')}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Createdbox;