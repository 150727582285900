import React from 'react';
import i18next from 'i18next';

export function Footbtn(props) {
  const { disabled, handlecancel, handleconfirm, noframes, logout, bindloading, cancelBtn, noframesHandlecancel } = props;
  
  return (
    <div className='dialog-handle'>
      {noframes && !bindloading && !cancelBtn && <span
        className='cancel-btn'
        onClick={() => { logout && logout() }}>{i18next.t('ChangeTheAccount')}</span>}
      {
        cancelBtn && <span
          className='cancel-btn'
          onClick={() => { noframesHandlecancel ? noframesHandlecancel() : handlecancel() }}>{i18next.t('Cancel')}</span>
      }
      <span
        className={`confirm-btn ${disabled ? 'disabled-btn' : ''}`}
        onClick={handleconfirm}>{i18next.t('Determine')}</span>
    </div>
  )
}