import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Footbtn } from './Footbtn';
import { message, Icon } from 'antd';
import i18next from 'i18next';

@withTranslation()
class Loading extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  onOK = () => {
    if (this.props.disabled) {
      return;
    }
    let val = this.inputRef.current.value.replace(/\s/ig, '');
    if (val === '' || val.length < 16) {
      message.error(i18next.t('serial'));
      return;
    }
    this.props.handleconfirm(val)
  }

  render() {
    const {
      t,
      disabled,
      inputReg,
      handlecancel,
      bindloading,
      bindfailed,
      offline,
      noframes,
      logout,
      cancelBtn,
      noframesHandlecancel } = this.props;
    return (
      <div>
        {
          bindloading ? (
            <div className='framebind-loading'>
              <p className='loading-icon'><Icon type="loading" spin /></p>
              <p className='p-txt'></p>
              <p className='p-txt'>{t('confirmBinding')}</p>
            </div>
          ) : bindfailed ? (
            <div className='framebind-failed'>
              <p className='failed-icon'><Icon type="close-circle" theme="filled" /></p>
              <p className='p-txt'>{t('ValidationFailed')}</p>
              <p className='p-txt'>{t('tryAgain')}</p>
              {offline && <p className='p-txt offline'>{t('notOnline')}</p>}
            </div>
          ) : (
                <div className='dialog-input' style={noframes ? { paddingTop: '12px' } : {}}>
                  {noframes && !bindloading && <div className='fristbind'>{t('framebindfirst')}</div>}
                  <input id='serial' autoFocus onInput={inputReg} maxLength='19' ref={this.inputRef} type="text" placeholder={t('enterSerial')} />
                  <p>{t('getSerial')}</p>
                  <p>{t('action')}</p>
                </div>
              )
        }

        <Footbtn
          disabled={disabled}
          noframes={noframes}
          handlecancel={handlecancel}
          logout={logout}
          bindloading={bindloading}
          cancelBtn={cancelBtn}
          noframesHandlecancel={noframesHandlecancel}
          handleconfirm={this.onOK} />
      </div>
    );
  }
}

export default Loading;