import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import i18next from "i18next";
import { inject, observer } from "mobx-react";
import { Icon, message, Row, Col, Button } from "antd";
import Nodata from "../../common/Widget/Nodata";
import { trim, reg, selfAdaption2 } from "../../util/index";
import { get, observable } from "mobx";
import Loading from "../../common/Widget/Loading";
import Sorter from "../../common/Sorter";
import SmallPlaylist from "../../common/SmallPlaylist";

@withTranslation()
@inject("playlist")
@observer
class Selectlist extends Component {
  constructor(props) {
    super(props);
    this.nameRef = React.createRef();
    this.creNameRef = React.createRef();
    this.listBoxRef = React.createRef();
    this.state = {
      sortList: [
        { id: 1, text: "updateSort" },
        { id: 2, text: "creationSort" },
        { id: 3, text: "photosSort" },
        { id: 4, text: "nameSort" },
      ],
      defaultSort: {},
      sortindex: 0,
      sortDis: false,
      isdes: false,
      checklist: {},
    };
  }
  @observable list = null;
  @observable sortList = [
    { id: 1, text: "updateSort" },
    { id: 2, text: "creationSort" },
    { id: 3, text: "photosSort" },
    { id: 4, text: "nameSort" },
  ];
  @observable defaultSort = {};
  @observable sortindex = 0;
  @observable sortDis = false;
  @observable isdes = false;
  @observable checklist = {};
  @observable listW = 0;
  flag = false;

  componentDidMount() {
    this.defaultSort = this.sortList[0];
    this.props.playlist.getplaylist(0, false, () => {
      if (this.props.farmedata.id) {
        let pos = this.props.playlist.bindplaylist.findIndex(
          (el) => el.id === this.props.farmedata.id
        );
        this.checklist = this.props.playlist.bindplaylist[pos];
      }
      this.listW = selfAdaption2(this.listBoxRef.current.offsetWidth);
    });
    if (this.props.playlist.bindplaylistinit && this.props.farmedata.id) {
      let pos = this.props.playlist.bindplaylist.findIndex(
        (el) => el.id === this.props.farmedata.id
      );
      this.checklist = this.props.playlist.bindplaylist[pos];
    }

    document.body.addEventListener("click", this.hide);
    window.addEventListener("resize", this.resize);
  }
  componentWillUnmount() {
    this.props.playlist.framesbindcancel();
    document.body.removeEventListener("click", this.hide);
    window.removeEventListener("resize", this.resize);
  }

  resize = () => {
    this.listW = selfAdaption2(this.listBoxRef.current.offsetWidth);
  };

  // 更多/排序modal隐藏
  hide = (e) => {
    this.sortDis = false;
  };

  pitchOn = (item) => {
    if (!this.props.playlist.flag) {
      this.checklist = item;
    }
  };

  //排序
  sortEldis = (e) => {
    e.nativeEvent.stopImmediatePropagation();
    this.sortDis = !this.sortDis;
  };

  //点击排序
  changeIndex = (i) => {
    if (!this.props.playlist.flag) {
      if (this.sortindex === i) {
        this.isdes = !this.isdes;
      } else {
        this.sortindex = i;
      }
      this.defaultSort = this.sortList[i];
      this.props.playlist.sorting(i, this.isdes);
    }
  };

  //筛选
  filtration = () => {
    if (!this.props.playlist.flag) {
      const val = this.nameRef.current.value;
      this.props.playlist.filtration(val, this.sortindex, this.sortDis);
    } else {
      this.nameRef.current.value = "";
    }
  };

  //新建列表
  createdfbplay = () => {
    const val = trim(this.creNameRef.current.value);
    if (!val) {
      message.error(i18next.t("enterName"));
      return;
    }
    if (!reg.playlistname.test(val)) {
      message.error(i18next.t("Listnamedoes"));
      return;
    }
    this.props.playlist.creframesbindplay(val);
  };

  createPlaylist = () => {
    this.checklist = {};
    if (!this.props.playlist.flag) {
      this.props.playlist.framesbindcreplay();
    }
  };

  close_cre = () => {
    this.props.playlist.close_cre();
  };

  onOk = () => {
    if (!this.checklist.id) {
      message.error(i18next.t("Noselected"));
      return;
    }
    this.props.handleOk(this.checklist);
  };

  cancel = () => {
    this.props.playlist.close_cre();
    this.props.cancel();
  };

  render() {
    const { t } = this.props;
    const { sortList, defaultSort, isdes, sortDis, checklist, listW } = this;
    const { bindplaylistinit, bindplaylist } = this.props.playlist;
    let playel;
    if (bindplaylistinit) {
      if (bindplaylist.length > 0) {
        playel = bindplaylist.map((item) => {
          if (get(item, "name")) {
            return (
              <SmallPlaylist
                key={item.id}
                listW={listW}
                item={item}
                pitchOn={(item) => {
                  this.pitchOn(item);
                }}
                checklist={checklist}
              />
            );
          } else {
            return (
              <div className="list_BOX" style={{ width: listW }} key={item.id}>
                <div className="cre-list-box">
                  <span className="cre-list-close" onClick={this.close_cre}>
                    <Icon type="close" />
                  </span>
                  <div className="cre-top-img" style={{ height: listW * 0.58 }}>
                    <Icon type="profile" />
                  </div>
                  <div className="cre-bottom-input">
                    <input
                      ref={this.creNameRef}
                      maxLength={50}
                      autoFocus
                      type="text"
                      placeholder={t("NewPlaylist")}
                      style={{ width: listW * 0.58 }}
                    />
                    <span onClick={this.createdfbplay}>
                      <Icon type="check" />
                    </span>
                  </div>
                </div>
              </div>
            );
          }
        });
      } else {
        playel = <Nodata />;
      }
    } else {
      playel = <Loading />;
    }

    return (
      <div className="module-canvas">
        <div className="Selectlist-inner">
          <div className="Selectlist-head">
            <span>{t("DefaultPlaylist")}</span>
            <span onClick={this.cancel}>
              <Icon type="close" />
            </span>
          </div>
          {bindplaylistinit && (
            <Button
              type="primary"
              icon="plus"
              size="small"
              className="Selectlist_576_btn"
              onClick={this.createPlaylist}
            />
          )}
          {bindplaylistinit && (
            <Row className="head_op" gutter={[0, 8]}>
              <Col xs={{ span: 24 }} sm={{ span: 12 }}>
                <Sorter
                  className="Sorter"
                  defaultSort={defaultSort || {}}
                  sortList={sortList}
                  isdes={isdes}
                  changeIndex={this.changeIndex}
                  sortDis={sortDis}
                  sortEldis={this.sortEldis}
                />
              </Col>
              <Col xs={{ span: 0 }} sm={{ span: 12 }}>
                <div className="Selectlist-filtrate">
                  <input
                    ref={this.nameRef}
                    type="text"
                    placeholder={t("Screen")}
                    onInput={this.filtration}
                  />
                  <Icon type="filter" />
                </div>
              </Col>
            </Row>
          )}
          <div className="Selectlist-content-list" ref={this.listBoxRef}>
            {playel}
          </div>
          <div className="Selectlist-foot clearfix">
            <div className="create-playlist-btn" onClick={this.createPlaylist}>
              <Icon type="plus" />
              <span>{t("NewPlaylist")}</span>
            </div>
            <div className="Selectlist-btns">
              <span className="cancel" onClick={this.cancel}>
                {t("Cancel")}
              </span>
              <span className="affirm" onClick={this.onOk}>
                {t("Determine")}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Selectlist;
