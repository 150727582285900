// eslint-disable-next-line
String.prototype.format = function (args) {
  var result = this;
  if (arguments.length > 0) {
    // eslint-disable-next-line
    if (arguments.length == 1 && typeof (args) == "object") {
      for (var key in args) {
        // eslint-disable-next-line
        if (args[key] != undefined) {
          var reg = new RegExp("\\{\\s*" + key + "\\s*\\}", "g");
          result = result.replace(reg, args[key]);
        }
      }
    } else {
      for (var i = 0; i < arguments.length; i++) {
        // eslint-disable-next-line
        if (arguments[i] != undefined) {
          // eslint-disable-next-line
          var reg = new RegExp("\\{\\s*" + i + "\\s*\\}", "g");
          result = result.replace(reg, arguments[i]);
        }
      }
    }
  }
  return result;
};
