import { observable, action } from "mobx";
import login from "./../login/index";
import { wsget } from "../../ws";

class feedback {
  @observable init = false;
  @observable listData = [];
  @observable detailInit = false;
  @observable detail = [];
  @observable empty = false;
  @observable id = null;
  @observable subject = null;
  @observable created = null;
  user_id = null;

  @action getData = (cb) => {
    wsget("feedback_list", null).then((data) => {
      this.listData = data;
      this.user_id = login.user_info.id;
      if (data.length) {
        this.getDetail({
          id: data[0].id,
          subject: data[0].subject,
          created: data[0].created,
        });
      } else {
        this.empty = true;
      }
      this.init = true;
    }).catch(err => {
      cb && cb(err)
    });;
  };

  @action getDetail = ({ id, subject, created }) => {
    if (this.id !== id) {
      this.detailInit = false;
      if (this.id) {
        const pos = this.listData.findIndex((el) => el.id === this.id);
        this.listData[pos].unread = 0;
      }
      login.getUser_detail();
    }
    this.id = id;
    this.subject = subject;
    this.created = created;
    wsget("feedback_item", id).then((data) => {
      this.detail = data;
      this.detailInit = true;
      this.empty = false;
    });
  };

  @action refreshtDetail = (id) => {
    wsget("feedback_list", null).then((data) => {
      this.listData = data;
      this.id = data[0].id;
      this.subject = data[0].subject;
      this.created = data[0].created;
      wsget("feedback_item", id).then((data) => {
        if (this.id === id) {
          this.detail = data;
        }
      });
    });
    login.getUser_detail();
  };

  @action reset = () => {
    this.init = false;
    this.listData = [];
    this.detailInit = false;
    this.detail = [];
    this.empty = false;
    this.id = null;
    this.subject = null;
    this.created = null;
  };
}

export default new feedback();
