import { set, remove, observable, action } from "mobx";
import event from "../../util/event";
import { wsget } from "../../ws";
import { imgLoad, getFile, adaptation } from "../../util/index";
import { message } from "antd";
import upload from "../uploadFiles/index";
import i18next from "i18next";

const minH = 300;
const minW = 270;

const minW2 = 250;

class playdetail {
  @observable initialData = []; //列表数据
  @observable init = false; //初始化
  @observable frameinit = false; //初始化
  @observable filenum = 0; //文件数量
  @observable frameList = []; //绑定相框de列表
  @observable frameListNum = null; //绑定相框个数
  @observable updated = null; //更新时间
  @observable columnCount = null; //行数
  @observable rowCount = null; //列数
  @observable removeFileids = []; //选中的图片的列表
  @observable bImg_visible = false; //大图modul显隐
  @observable imgfile = {}; //大图文件
  @observable imgidx = null; //切换图片的标识
  @observable frames = null; //相框列表
  @observable framelists = [];
  @observable num = 0;
  @observable allcheck = false;

  //获取数据
  @action getData = (id, getWH, getH, width) => {
    this.init = false;
    this.id = id;
    this.close = false;
    this.width = width;
    this.getH = getH;
    this.getWH = getWH;
    this.num = 0;
    wsget("playlist_detail2", id)
      .then((res) => {
        if (!res.list) {
          res.list = [];
        }
        this.updated = res.updated;
        this.filenum = res.list.length;
        this.columnCount = getWH();
        this.pushData(res.list);
        getH();
        if (!this.close) {
          this.init = true;
        }

        // // //上传
        // if (!this.playupload) {
        //   this.playupload = event.on("playupload", (res) => {
        //     if (id === res.id && !this.close) {
        //       this.updated = res.msg.updated;
        //     }
        //   });
        // }

        //添加文件
        if (!this.addfile) {
          this.addfile = event.on("savefiles", (res) => {
            if (id === res.id && !this.close) {
              this.updated = res.data.updated;
              this.initialData.unshift(res.list[0]);
              getH();
              this.resize(this.getWH);
              this.filenum += 1;
            }
          });
        }
      })
      .catch((res) => {
        if (window.ws) {
          if (window.ws.isconnected() && res.state !== 0) {
            this.getData(id, getWH, getH, width);
          }
        }
      });
  };

  @action getFrames = (id, width) => {
    this.width = width;
    wsget("playlist_detail_frames2", id).then((data) => {
      this.frameList = adaptation(width, observable(data), minW, minH, minW2);
      this.frameinit = true;
    });
  };

  @action resize2 = (width, arr) => {
    this.width = width;
    this.frameList = adaptation(width, arr, minW, minH, minW2);
  };

  //选中图片
  @action checkclick = (e, item) => {
    e.stopPropagation();
    if (this.removeFileids.length > this.filenum) {
      this.initialData.forEach((item) => {
        if (item.true) {
          delete item.check;
        }
      });
    }
    if (item.check) {
      remove(item, "check");
      this.num -= 1;
    } else {
      set(item, { check: true });
      this.num += 1;
    }
    this.removeArr();
  };

  //全选
  @action oncheckAll = () => {
    if (this.initialData.length !== this.removeFileids.length) {
      this.num = 0;
      this.initialData.forEach((el) => {
        set(el, { check: true });
        if (!el.true) {
          this.num += 1;
        }
      });
    } else {
      this.initialData.forEach((el) => {
        remove(el, "check");
      });
      this.num = 0;
    }
    this.removeArr();
  };

  //取消选择
  @action cancelAll = () => {
    this.initialData.forEach((el) => {
      remove(el, "check");
    });
    this.num = 0;
    this.removeArr();
  };

  //反选
  @action invert = () => {
    this.removeFileids = [];
    this.num = 0;
    this.initialData.forEach((el) => {
      if (el.check) {
        remove(el, "check");
      } else {
        set(el, { check: true });
        if (!el.true) {
          this.num += 1;
        }
      }
    });
    this.removeArr();
  };

  @action removeArr = () => {
    this.removeFileids = this.initialData.filter((item) => {
      return item.check;
    });
    if (this.removeFileids.length === this.filenum) {
      this.initialData.forEach((el) => {
        if (el.true) {
          set(el, { check: true });
          this.removeFileids.push(el);
        }
      });
    }
  };

  //删除图片
  @action remove_img = (id) => {
    let newArr = this.initialData.filter((item) => {
      return !item.check && !item.true;
    });
    newArr.forEach((element) => {
      delete element.completed;
      delete element.check;
    });
    let data = {
      id,
      list: newArr,
      updated: this.updated,
    };
    this.upfiles();
    wsget("playlist_save", data, 1).then((data) => {
      event.emit("updatetime", data.updated);
      this.updated = data.updated;
      this.filenum = data.list.length;
      this.initialData = data.list;
      this.removeFileids = [];
      this.resize(this.getWH);
      this.num = 0;
    });
  };

  //点击查看大图
  @action b_img_show = (i) => {
    const reg = /(avi|mov|rmvb|rm|flv|mp4|3gb)$/;
    this.bImg_visible = true;
    this.imgfile = this.initialData[i];
    this.imgidx = i;
    if (!reg.test(this.initialData[i].ext)) {
      if (i + 1 < this.filenum) {
        if (!reg.test(this.initialData[i + 1].ext)) {
          imgLoad(getFile.file_b(this.initialData[i + 1].id));
        }
      }
      if (!(i - 1 < 0)) {
        if (!reg.test(this.initialData[i - 1].ext)) {
          imgLoad(getFile.file_b(this.initialData[i - 1].id));
        }
      }
    } else {
      if (i + 1 < this.filenum) {
        if (!reg.test(this.initialData[i + 1].ext)) {
          imgLoad(getFile.file_b(this.initialData[i + 1].id));
        }
      }
      if (i - 1 >= 0) {
        if (!reg.test(this.initialData[i - 1].ext)) {
          imgLoad(getFile.file_b(this.initialData[i - 1].id));
        }
      }
    }
  };
  //关闭大图
  @action closeDialog = () => {
    this.bImg_visible = false;
  };
  //上一张
  @action onPiece = () => {
    if (this.imgidx - 1 >= 0) {
      this.b_img_show(this.imgidx - 1);
    } else {
      this.imgidx = this.filenum - 1;
      this.b_img_show(this.imgidx);
      message.warning(i18next.t("firstOne"));
    }
  };
  //下一张
  @action nextPage = () => {
    if (this.imgidx + 1 >= this.filenum) {
      this.imgidx = 0;
      this.b_img_show(this.imgidx);
      message.warning(i18next.t("lastOne"));
      return;
    }
    this.b_img_show(this.imgidx + 1);
  };

  //添加描述
  @action addCpt = (cpt) => {
    const { id } = this;
    let newArr = this.initialData.filter((item) => {
      return !item.true;
    });
    newArr.forEach((item, i) => {
      delete item.key;
      delete item.check;
      delete item.completed;
    });
    if (cpt !== "") {
      newArr[this.imgidx]["cpt"] = cpt;
      let data = {
        id,
        list: newArr,
        updated: this.updated,
      };
      this.upfiles();
      wsget("playlist_save", data, 1).then((data) => {
        event.emit("updatetime", data.updated);
        this.updated = data.updated;
        // set(this.initialData[this.imgidx], { cpt })
        this.pushData(data.list);
        this.b_img_show(this.imgidx);
      });
    } else {
      delete newArr[this.imgidx].cpt;
      let data = {
        id,
        list: newArr,
        updated: this.updated,
      };
      this.upfiles();
      wsget("playlist_save", data, 1).then((data) => {
        event.emit("updatetime", data.updated);
        this.updated = data.updated;
        delete this.initialData[this.imgidx].cpt;
        this.pushData(data.list);
        this.b_img_show(this.imgidx);
        this.num = 0;
      });
    }
  };

  //获取相框列表
  @action framesResize = (arr, width) => {
    this.frames = adaptation(width, arr, minW, minH, minW2);
  };
  @action getframes = (id, width) => {
    this.framelists = [];
    wsget("frame_list", null).then((data) => {
      data.forEach((item) => {
        if (this.frameList.findIndex((el) => el.node === item.node) > -1) {
          item.check = true;
          this.framelists.push(item.node);
        }
        if (item.playlist_id === id) {
          item.default = true;
        }
      });
      this.frames = adaptation(width, observable(data), minW, minH, minW2);
    });
  };
  //选中相框
  @action checkFrames = (item) => {
    const idx = this.frames.findIndex((el) => el.node === item.node);
    if (!item.check) {
      if (idx > -1) {
        set(item, "check", true);
        this.framelists.push(item.node);
      }
    } else {
      if (!item.default) {
        remove(item, "check");
        this.framelists.remove(item.node);
      }
    }
  };

  @action checkAllfn = () => {
    if (this.framelists.length !== this.frames.length) {
      this.framelists = [];
      this.frames.forEach((item) => {
        set(item, "check", true);
        this.framelists.push(item.node);
      });
    } else {
      this.frames.forEach((item) => {
        if (!item.default) {
          remove(item, "check");
          let pos = this.framelists.findIndex((el) => el === item.node);
          this.framelists.splice(pos, 1);
        }
      });
      this.framelists = [];
    }
  };

  //选中相框确认
  @action handleOk = () => {
    const { frames } = this;
    wsget(
      "playlist_save_frames",
      { id: this.id, frames: this.framelists },
      1
    ).then(() => {
      const newArr = [];
      this.framelists.forEach((el) => {
        const idx = frames.findIndex((item) => item.node === el);
        if (idx > -1) {
          newArr.push(frames[idx]);
        }
      });
      this.frameList = adaptation(
        this.width,
        observable(newArr),
        minW,
        minH,
        minW2
      );
      this.frameListNum = newArr.length;
      this.framelists = [];
      this.frames = null;
    });
  };
  //选中相框取消
  @action framesno = () => {
    this.frames = null;
  };

  //移动最前
  @action moveTop = (id) => {
    let list = [];
    let arr = JSON.parse(JSON.stringify(this.initialData));
    let newArr = this.initialData.filter((item) => {
      return item.check && !item.true;
    });
    newArr.forEach((item) => {
      let pos = arr.findIndex((el) => el.id === item.id);
      arr.splice(pos, 1);
      arr.unshift(item);
    });
    arr.forEach((item) => {
      delete item.completed;
      delete item.check;
      if (!item.true) {
        list.push(item);
      }
    });
    let obj = {
      id,
      list,
      updated: this.updated,
    };
    this.upfiles();
    wsget("playlist_save", obj, 1).then((data) => {
      event.emit("updatetime", data.updated);
      this.updated = data.updated;
      this.pushData(data.list);
      this.getH();
      this.resize(this.getWH);
      this.removeFileids = [];
      this.num = 0;
    });
  };

  //移动最后
  @action moveLast = (id) => {
    let list = [];
    let arr = JSON.parse(JSON.stringify(this.initialData));
    let newArr = this.initialData.filter((item) => {
      return item.check && !item.true;
    });
    newArr.forEach((item) => {
      let pos = arr.findIndex((el) => el.id === item.id);
      arr.splice(pos, 1);
      arr.push(item);
    });
    arr.forEach((item) => {
      delete item.completed;
      delete item.check;
      if (!item.true) {
        list.push(item);
      }
    });
    let obj = {
      id,
      list,
      updated: this.updated,
    };
    this.upfiles();
    wsget("playlist_save", obj, 1).then((data) => {
      event.emit("updatetime", data.updated);
      this.updated = data.updated;
      this.pushData(data.list);
      this.getH();
      this.resize(this.getWH);
      this.removeFileids = [];
      this.num = 0;
    });
  };

  @action pushData = (list) => {
    if (list.length % this.columnCount !== 0) {
      this.fornum = this.columnCount - (list.length % this.columnCount);
      for (let index = 0; index < this.fornum; index++) {
        list.push({ true: true });
      }
    }
    this.initialData = list;
    return list;
  };
  @action resize = (cb) => {
    let newArr = this.initialData.filter((item) => {
      return !item.true;
    });
    this.columnCount = cb();
    this.pushData(newArr);
    this.getH();
  };

  @action upfiles = () => {
    if (upload.finish) {
      clearTimeout(upload.timeInterval);
      upload.palyArr = [];
      upload.batchSave();
    }
  };

  @action clearData = () => {
    this.close = true;
    this.init = false;
    this.frameinit = false;
    event.rm(this.playupload);
    event.rm(this.addfile);
    this.playupload = null;
    this.addfile = null;
    this.removeFileids = [];
    this.filenum = 0; //文件数量
    this.frameListNum = null; //绑定相框个数
    this.updated = null; //更新时间
  };
}

export default new playdetail();
