import React from "react";
import {
  Icon,
  Tooltip,
  Modal,
  Avatar,
  Menu,
  Dropdown,
  Spin,
  Badge,
  Drawer,
} from "antd";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import "./style.scss";
import { host, wsget } from "../../ws";
import { storage, removeCookie } from "../../util/index";
import Languageselect from "../../common/Languageselect";
import { buyUrl } from "../../util/url";
import i18next from "i18next";
import { langList } from "../../lang";
import i18n, { setLanguage } from '../../i18n'
import { get } from "mobx";

const { confirm } = Modal;

@withRouter
@withTranslation()
@inject("login", "photoalbum", "rahmen", "playlist", "uploadFiles", "feedback")
@observer
class HeaderBar extends React.Component {
  constructor(props) {
    super(props);
    this.headBar = React.createRef();
    const list = langList.map((item, i) => {
      return {
        language: item.name,
        abbreviation: item.key,
      };
    });

    this.state = {
      languageNav: false,
      selectUser: false,
      user_data: {},
      languagelist: list,
      languageinit: {},
    };
  }

  componentDidMount() {
    let index = this.state.languagelist.findIndex((item) => {
      return item.abbreviation === i18n.locale;
    });
    this.setState({ languageinit: this.state.languagelist[index] });

    document.addEventListener("click", this.hide);
  }
  componentWillUnmount() {
    document.removeEventListener("click", this.hide);
  }

  hide = () => {
    this.setState({ selectUser: false });
  };

  selectInfo = (e) => {
    e.nativeEvent.stopImmediatePropagation();
    this.setState({ selectUser: !this.state.selectUser });
  };
  //跳转个人信息页
  goPersonalDetails = () => {
    this.props.history.push("/personal");
  };

  clear = () => {
    storage.remove("user");
    removeCookie("lovtok");
    this.props.login.clearData();
    this.props.photoalbum.clearData();
    this.props.rahmen.clearData();
    this.props.playlist.clearData();
    this.props.uploadFiles.clearData();
    this.props.feedback.reset();
    wsget("user_logout", null);
    this.props.history.push("/login");
  };

  //退出登录
  loginout = () => {
    let _this = this;
    confirm({
      title: this.props.t("loginout"),
      okText: i18next.t("Determine"),
      cancelText: i18next.t("Cancel"),
      onOk() {
        _this.clear();
      },
      onCancel() { },
    });
  };

  toClear = () => {
    this.props.history.push(`/clear`);
  };

  getPopupContainer = () => {
    return this.headBar.current;
  };

  goFeedback = () => {
    this.props.history.push(`/feedback`);
  };

  dropdownClick = ({ key }) => {
    if (key === "0") {
      this.goPersonalDetails();
    } else if (key === "1") {
      this.goFeedback();
    } else if (key === "2") {
      this.loginout();
    }
  };

  DrawerTopClick = ({ key }) => {
    switch (key) {
      case "0":
        this.goPersonalDetails();
        break;
      case "1":
        this.goFeedback();
        break;
      case "3":
        this.loginout();
        break;
      case "4":
        this.chooseLanguage(0);
        break;
      case "5":
        this.chooseLanguage(1);
        break;
      case "6":
        this.chooseLanguage(2);
        break;
      default:
        break;
    }
    this.props.onClose();
  };

  chooseLanguage = (key) => {
    this.setState({ languageinit: this.state.languagelist[key] }, () => {
      setLanguage(this.state.languageinit.abbreviation)
    });
  };

  render() {
    const { t, onClose, visible, user } = this.props;
    const { selectUser, languageinit, languagelist } = this.state;
    const { user_info } = this.props.login;

    const menu = (
      <Menu onClick={this.dropdownClick}>
        <Menu.Item className="headBar_span" key="0">
          <span>{t("account")}</span>
        </Menu.Item>
        <Menu.Item className="headBar_span" key="1">
          <span>
            {get(user_info, 'unread') && <Badge status="error" />}
            {t("Feedback")}
          </span>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item className="headBar_span" key="2">
          <span>{t("Logout")}</span>
        </Menu.Item>
      </Menu>
    );

    return (
      <div className="headerbar" ref={this.headBar}>
        <div className="bars">
          <Languageselect />
          {user && (
            <Dropdown
              overlay={menu}
              placement="bottomCenter"
              trigger={["hover", "click"]}
            >
              <div
                className={`select-user treeNodeUnselectable ${selectUser ? "act-user" : null
                  }`}
                onClick={this.selectInfo}
              >
                <Badge count={get(user_info, 'unread') ? user_info.unread : 0}>
                  {user_info.profile ? (
                    <Avatar
                      style={{ border: "1px solid #ccc" }}
                      size={40}
                      src={`${host()}/uf/s/${user_info.profile.id}/${user_info.id}`}
                    />
                  ) : (
                      <Avatar size={40} icon="user" />
                    )}
                </Badge>

                <Tooltip
                  placement="left"
                  overlayClassName="tooltip"
                  title={
                    user_info.nickname ? user_info.nickname : user_info.name
                  }
                >
                  {get(user_info, "name") ? (
                    <span className="user-name ellipsis">
                      {user_info.nickname ? user_info.nickname : user_info.name}
                    </span>
                  ) : (
                      <span
                        style={{ textAlign: "center", width: "60px" }}
                        className="user-name"
                      >
                        <Spin />
                      </span>
                    )}
                </Tooltip>
                <Icon type="down" />
              </div>
            </Dropdown>
          )}
        </div>

        <Drawer
          placement="top"
          closable={false}
          onClose={onClose}
          visible={visible}
          height="auto"
          destroyOnClose
        >
          <Menu onClick={this.DrawerTopClick} mode="inline" selectable={false}>
            <Menu.Item key="0">
              <span>
                {t("account")} (
                {user_info.nickname ? user_info.nickname : user_info.name})
              </span>
            </Menu.Item>
            <Menu.Item key="1">
              <span>
                {user_info.unread && <Badge status="error" />}
                {t("Feedback")}
              </span>
            </Menu.Item>
            <Menu.SubMenu
              key="sub1"
              title={
                languageinit.language
                  ? t("Language").format(languageinit.language)
                  : "Language"
              }
            >
              {languagelist.map((item, inx) => {
                return <Menu.Item key={inx}>{item.language}</Menu.Item>;
              })}
            </Menu.SubMenu>
            {buyUrl ? (
              <Menu.Item key="2">
                <a target="_blank" rel="noopener noreferrer" href={buyUrl}>
                  {t("Buyingphotoframes")}
                </a>
              </Menu.Item>
            ) : null}
            <Menu.Divider />
            <Menu.Item key="3">
              <span>{t("Logout")}</span>
            </Menu.Item>
          </Menu>
        </Drawer>
      </div>
    );
  }
}

export default HeaderBar;
