import { set, remove, observable, action } from "mobx";
import dayjs from "dayjs";
import { wsget } from "../../ws";
import { fileSort, myfilter, adaptation, selfAdaption } from "../../util/index";
import event from "../../util/event";

const minH = 285;
const minW = 240;

const minW2 = 180;
const minH2 = 215;

class playlist {
  @observable firstInit = false; //第一次加载播放列表
  @observable playList = []; //播放列表
  @observable removeplayList = []; //删除播放列表
  @observable selectdefaultList = []; //选中默认列表
  @observable detail_list = { list: [] }; //用来展示列表详情
  @observable detail_list_2 = { list: [] }; //操作的列表详情
  @observable i = 0; //标识key自增
  @observable removeFileids = []; //删除文件列表
  @observable frameList = []; //绑定相框列表
  @observable homeplayList = []; //...数据
  @observable homeplayListimg = {}; //首页播放列表图片
  @observable uploadlist = []; //上传框选中列表
  @observable asyncframelist = []; //同步相框的列表
  @observable cre_visible = false; //新建列表的显隐
  @observable frame_list = []; //相框列表
  @observable bindframplaylist = []; //绑定相框选择列表
  @observable bindframplaylist_2 = []; //绑定相框选择列表2
  @observable flag = false; //绑定相框选择列表
  @observable bindplaylist = []; //绑定相框选择列表
  @observable bindplaylistinit = false; //绑定相框选择列表
  urlData = {}; //跳转详情页的url数据

  //播放列表初始化
  @action playlist_init(sort, isdes, width, cb, Row) {
    this.width = width;
    this.Row = Row;
    wsget("playlist_list", null, 0)
      .then((data) => {
        // this.bindframplaylist = fileSort.update(observable(data));
        // this.bindframplaylist_2 = this.bindframplaylist;
        this.removeFileids = [];
        // let newArr = this.sort(observable(data), sort, isdes);
        // this.playList = adaptation(width, newArr, minW, minH, minW2);
        const arr = this.sort(observable(data), sort, isdes);
        const obj = this.utils(width, arr);
        cb && cb(obj);
        this.firstInit = true;
      })
      .catch((res) => {
        console.log(res);
        // if (window.ws) {
        //   if (window.ws.isconnected() && res.state !== 0) {
        //     this.playlist_init(sort, isdes, width)
        //   }
        // }
      });
  }

  @action resize = (width, arr) => {
    this.width = width;
    this.playList = adaptation(width, arr, minW, minH, minW2, minH2);
  };

  @action utils = (width, arr) => {
    this.width = width;
    const obj = selfAdaption(width, minW, minH, minW2, minH2);
    const listColumnNum = obj.colNum;
    let data = arr || this.playList.filter((el) => el.id);
    let rowNum = Math.ceil(data.length / listColumnNum);
    if (data.length % listColumnNum !== 0) {
      const leng = listColumnNum - (data.length % listColumnNum);
      for (let index = 0; index < leng; index++) {
        data.push({ true: true });
      }
    }
    this.Row(rowNum);
    this.playList = data;

    return { ...obj, rowNum };
  };

  @action sort = (data, sort, isdes) => {
    if (isdes) {
      switch (sort) {
        case 0:
          //按更新时间排序
          return fileSort.update(data).reverse();
        case 1:
          //按创建时间排序
          return fileSort.create(data).reverse();
        case 2:
          //按照片数量排序
          return fileSort.filecount(data).reverse();
        case 3:
          //按文件名排序
          return fileSort.Filename(data).reverse();
        default:
          break;
      }
    } else {
      switch (sort) {
        case 0:
          //按更新时间排序
          return fileSort.update(data);
        case 1:
          //按创建时间排序
          return fileSort.create(data);
        case 2:
          //按照片数量排序
          return fileSort.filecount(data);
        case 3:
          //按文件名排序
          return fileSort.Filename(data);
        default:
          break;
      }
    }
  };

  //新建播放列表
  @action addpalylist(name) {
    this.cre_visible = false; //新建列表modal关闭
    wsget("playlist_create", name, 1).then((data) => {
      const playdata = {
        id: data,
        name,
        created: dayjs().format(),
        frame_count: 0,
        frame_default: 0,
        updated: dayjs().format(),
        filecount: 0,
      };
      this.playList.unshift(playdata);
      this.utils(this.width);

      event.emit("addPlaylist");
      this.bindframplaylist = fileSort.update(this.playList);
      this.bindframplaylist_2 = fileSort.update(this.playList);
    });
  }
  //新建播放列表隐藏
  @action cre_handlecancel = () => {
    this.cre_visible = false;
  };
  //新建播放列表显示
  @action createPlaylist = () => {
    this.cre_visible = true;
  };

  //列表排序1
  @action playlist_sort1 = (i, isdes) => {
    const arr = this.playList.filter((el) => el.id);
    this.playList = this.sort(arr, i, isdes);
  };

  //列表重命名
  @action playlist_rename(id, name) {
    let data = { id, name: name.toString() };
    const i = this.playList.findIndex((item) => item.id === id);
    if (this.playList[i].name !== name) {
      wsget("playlist_rename", data, 1).then(() => {
        this.playList[i].name = name;
        this.utils(this.width);
      });
    }
  }

  //选中列表
  @action pitchOn = (e, id, frame_def, cb1, cb2) => {
    e.stopPropagation();
    let pos = (this.playList || []).findIndex((item) => item.id === id);
    if (pos > -1 && this.playList[pos].check) {
      remove(this.playList[pos], "check");
      this.removeplayList.remove(id);
      cb2 && cb2();
    } else {
      set(this.playList[pos], { check: true });
      if (frame_def > 0) {
        this.selectdefaultList.push(id);
      }
      this.removeplayList.push(id);
      if (this.playList.length === this.removeplayList.length) {
        cb1 && cb1();
      }
    }
  };

  //全选列表
  @action checkAllplaylist(checkAll) {
    const arr = this.playList.filter((el) => el.id);
    if (this.removeplayList.length !== arr.length) {
      for (let i = 0; i < arr.length; i++) {
        if (!this.playList[i].check) {
          set(this.playList[i], { check: true });
          if (this.playList[i].frame_default > 0) {
            this.selectdefaultList.push(this.playList[i].id);
          }
          this.removeplayList.push(this.playList[i].id);
        }
      }
    } else {
      for (let i = 0; i < arr.length; i++) {
        remove(this.playList[i], "check");
      }
      this.removeplayList = [];
      this.selectdefaultList = [];
    }
  }

  //取消选择
  @action cancelAll = () => {
    const arr = this.playList.filter((el) => el.id);
    for (let i = 0; i < arr.length; i++) {
      remove(this.playList[i], "check");
    }
    this.removeplayList = [];
    this.selectdefaultList = [];
  };

  //反选
  @action invert = () => {
    const arr = this.playList.filter((el) => el.id);
    this.removeplayList = [];
    arr.forEach((el) => {
      if (el.check) {
        remove(el, "check");
        this.removeplayList.remove(el.id);
      } else {
        set(el, { check: true });
        this.removeplayList.push(el.id);
      }
    });
  };

  //删除列表
  @action playlist_delete = (id) => {
    if (id) {
      wsget("playlist_delete", id, 1).then(() => {
        let arr = this.playList.filter((item) => item.id && item.id !== id[0]);
        this.utils(this.width, arr);
        this.removeplayList.remove(id[0]);
        this.bindframplaylist = fileSort.update(this.playList);
        this.bindframplaylist_2 = fileSort.update(this.playList);
      });
    } else {
      const newArr = this.removeplayList.filter(
        (item) => this.selectdefaultList.indexOf(item) === -1
      );
      wsget("playlist_delete", newArr, 1).then(() => {
        let arr = this.playList.filter((item) => {
          remove(item, "check");
          return item.id && newArr.indexOf(item.id) === -1;
        });
        this.utils(this.width, arr);
        this.removeplayList = [];
        this.selectdefaultList = [];
        this.bindframplaylist = fileSort.update(this.playList);
        this.bindframplaylist_2 = fileSort.update(this.playList);
      });
    }
  };

  //获取绑定相框列表
  @action playlist_detail_frames(id, cb) {
    wsget("playlist_detail_frames2", id).then((data) => {
      this.frameList = data;
      cb && cb();
    });
  }

  //添加相框的选择状态
  @action SetpitchOn = (node) => {
    let idx = this.frame_list.findIndex((item) => node === item.node);
    if (this.frame_list[idx].check) {
      remove(this.frame_list[idx], "check");
      this.asyncframelist.remove(this.frame_list[idx].node);
    } else {
      set(this.frame_list[idx], { check: true });
      this.asyncframelist.push(this.frame_list[idx].node);
    }
  };

  //确认添加相框
  @action playlist_save_frames = (id, asyncframelist) => {
    wsget("playlist_save_frames", { id, frames: asyncframelist }, 1).then(
      () => {
        event.emit("framesNum");
      }
    );
  };

  //播放到相框
  @action frameonPlay(id, node, cb) {
    wsget("playlist_play_in_frame", { id, frames: node }, 1)
      .then(() => {
        cb && cb();
      })
      .catch(() => {
        cb && cb("err");
      });
  }

  // //绑定相框选择默认列表
  // @action initlist = () => {
  //   this.bindframplaylist = fileSort.update(this.bindframplaylist_2);
  // }

  @action getplaylist = (sort, isdes, cb) => {
    wsget("playlist_list", null, 0)
      .then((data) => {
        this.bindplaylist = this.sort(observable(data), sort, isdes);
        this.oldlist = JSON.parse(JSON.stringify(data));
        cb && cb();
        this.bindplaylistinit = true;
      })
      .catch((res) => {
        if (window.ws) {
          if (window.ws.isconnected() && res.state !== 0) {
            this.getplaylist(sort, isdes, cb);
          }
        }
      });
  };

  @action framesbindcancel = () => {
    this.bindplaylistinit = false;
  };

  //新建
  @action framesbindcreplay = () => {
    if (!this.flag) {
      this.bindplaylist.unshift({
        id: -1,
      });
      this.flag = true;
    }
  };
  //新建2
  @action creframesbindplay = (name) => {
    if (this.bindname !== name) {
      this.bindname = name;
      wsget("playlist_create", name, 1).then((data) => {
        this.bindplaylist[0].id = data;
        set(this.bindplaylist[0], { name });
        set(this.bindplaylist[0], { filecount: 0 });
        set(this.bindplaylist[0], { updated: dayjs().format() });
        this.flag = false;
        this.bindname = null;
        event.emit("createplay");
      });
    }
  };

  // @action getbindframplaylist = (cb) => {
  //   wsget('playlist_list', null).then((data) => {
  //     this.bindframplaylist = fileSort.update(observable(data));
  //     this.bindframplaylist_2 = this.bindframplaylist;
  //     cb();
  //   })
  // }
  //取消新建
  @action close_cre = () => {
    if (this.flag) {
      this.bindplaylist.shift();
    }
    this.flag = false;
  };
  //确定后
  @action retflag = () => {
    if (this.flag) {
      this.bindframbindplaylist.shift();
    }
    this.flag = false;
  };
  //排序
  @action sorting(i, isdes) {
    this.bindplaylist = this.sort(this.bindplaylist, i, isdes);
  }
  //筛选
  @action filtration = (val, sort, isdes) => {
    const arr = myfilter(this.oldlist, "name", val);
    if (val !== "") {
      this.bindplaylist = arr;
    } else {
      this.bindplaylist = this.sort(observable(this.oldlist), sort, isdes);
    }
  };

  //清除重置
  @action replacement = () => {
    this.playList.forEach((el) => {
      remove(el, "check");
    });
    this.removeplayList = [];
  };

  //跳转详情页的url数据存储
  @action goUrl = (name, created,frame_count) => {
    this.urlData.name = name;
    this.urlData.created = created;
    this.urlData.frame_count = frame_count;
  };

  @action clearData = () => {
    this.firstInit = false; //第一次加载播放列表
    this.playList = []; //播放列表
    this.removeplayList = []; //删除播放列表
    this.selectdefaultList = []; //选中默认列表
    this.detail_list = { list: [] }; //用来展示列表详情
    this.detail_list_2 = { list: [] }; //操作的列表详情
    this.i = 0; //标识key自增
    this.removeFileids = []; //删除文件列表
    this.frameList = []; //绑定相框列表
    this.homeplayList = []; //...数据
    this.homeplayListimg = {}; //首页播放列表图片
    this.uploadlist = []; //上传框选中列表
    this.asyncframelist = []; //同步相框的列表
    this.cre_visible = false; //新建列表的显隐
    this.frame_list = []; //相框列表
    this.bindframplaylist = []; //绑定相框选择列表
    this.bindframplaylist_2 = []; //绑定相框选择列表2
    this.flag = false; //绑定相框选择列表
    this.urlData = {}; //跳转详情页的url数据
    this.drawer_visible = false; //抽屉显示
  };
}

export default new playlist();
