import React from 'react';
import { Empty } from 'antd';
import './style.scss';

function Nodata() {
  return (
    <div className="no-data">
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
  );
}

export default Nodata;