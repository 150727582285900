import React, { Component } from 'react';
import { message, Icon, Modal } from 'antd';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react';
import { wsget } from '../../ws';
import './style.scss';
import { observable } from 'mobx';
import event from '../../util/event';
import Loading from './Loading';
import Success from './Success';
import { storage, removeCookie } from '../../util/index';

const { confirm } = Modal;

@withRouter @withTranslation() @inject('login', 'photoalbum', 'rahmen', 'playlist', 'uploadFiles') @observer
class Framebinddialog extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.nameRef = React.createRef();
    this.t = this.props.t;
  }
  @observable visible = true;
  @observable bindloading = false;
  @observable bindfailed = false;
  @observable bindsuccess = true;
  @observable disabled = false;
  @observable defaultplaylistId = null;
  @observable farmedata = {};
  @observable offline = false;
  @observable sel_visible = false;
  @observable select_name = this.t('NoList');
  @observable already = false;
  @observable cancelBtn = false;

  clear = () => {
    storage.remove('user');
    removeCookie('lovtok');
    this.props.login.clearData();
    this.props.photoalbum.clearData();
    this.props.rahmen.clearData();
    this.props.playlist.clearData();
    this.props.uploadFiles.clearData();
    this.props.history.push('/login');
    wsget('user_logout', null);
  }

  logout = () => {
    let _this = this;
    confirm({
      title: this.props.t('loginout'),
      onOk() {
        _this.clear();
      },
      onCancel() { },
    });
  }

  inputReg = (e) => {
    //获取输入框的内容
    let val = e.target.value;
    //将所有的非数字替换为空      然后每四位加一个空格
    val = val.replace(/[^\d]/g, '').replace(/(\d{4})(?=\d)/g, '$1 ');
    e.target.value = val;
  }

  //输入序列号后确定
  handleconfirm1 = (val) => {
    this.bindloading = true;
    this.disabled = true;
    this.cancelBtn = true;
    wsget('frame_add', val, 0, 60000).then((data) => {
      if (!this.bindloading) {
        this.handlecancel();
      } else {
        this.visible = false;
        this.disabled = false;
        this.farmedata = data;
        if (data.playlist_id) {
          this.already = true;
          this.select_name = data.playlist_name;
          this.defaultplaylistId = data.playlist_id;
          document.getElementById('framename').value = data.framename;
        }
      }
    }).catch((res) => {
      if (!this.bindloading) {
        this.noframesHandlecancel();
        return;
      }
      if (res.state === 127) {
        this.bindloading = false;
        this.bindfailed = true;
        this.offline = true;
      } else {
        this.bindloading = false;
        this.bindfailed = true;
      }
    })
  }
  //相框成功响应之后确认
  handleconfirm2 = (val, obj) => {
    if (!this.click) {
      this.click = true;
      if (this.farmedata.playlist_id) {
        if (val === this.farmedata.framename && this.defaultplaylistId === this.farmedata.playlist_id) {
          this.bindloading = false;
          this.bindfailed = false;
          this.disabled = false;
          this.offline = false;
          this.farmedata = {}
          this.already = false;
          this.select_name = this.t('NoList');;
          this.defaultplaylistId = null;
          this.visible = true;
          this.props.rahmen.frameBindElhide();
          return;
        }
        obj.id = this.farmedata.playlist_id;
      }

      if (!val || !obj.id) {
        message.error(this.t('selectList'));
        return;
      }
      let data = {
        node: this.farmedata.node,
        framename: val,
        playlist_id: this.defaultplaylistId,
        token: this.farmedata.token
      }
      wsget('frame_after_add', data, 1).then(() => {
        this.bindloading = false;
        this.bindfailed = false;
        this.disabled = false;
        this.offline = false;
        this.visible = true;
        this.props.rahmen.frameBindElhide();
        this.click = false;
        this.cancelBtn = false;
        if (!this.farmedata.playlist_id) {
          let data = storage.get('user');
          data.frames = 1;
          storage.set('user', data)
          event.emit('add_frames');
        } else {
          event.emit('updata_frames');
        }
      })
    }

  }

  //取消
  handlecancel = () => {
    if (!this.bindloading && !this.bindfailed) {
      document.getElementById('serial').value = '';
    }
    this.bindloading = false;
    this.bindfailed = false;
    this.disabled = false;
    this.offline = false;
    this.farmedata = {}
    this.already = false;
    this.select_name = this.t('NoList');;
    this.defaultplaylistId = null;
    this.visible = true;
    this.props.rahmen.frameBindElhide();
  }

  noframesHandlecancel = () => {
    this.bindloading = false;
    this.bindfailed = false;
    this.disabled = false;
    this.offline = false;
    this.cancelBtn = false;
    this.farmedata = {}
    this.already = false;
    this.select_name = this.t('NoList');;
    this.defaultplaylistId = null;
    this.visible = true;
  }

  //选择默认列表确认
  handleOk = (obj) => {
    this.select_name = obj.name;
    this.defaultplaylistId = obj.id;
  }

  render() {
    // 9361 3434 3084 0855
    const { t, noframes } = this.props;
    // const { bindframedis } = this.props.rahmen;
    const {
      visible,
      bindloading,
      bindfailed,
      disabled,
      offline,
      select_name,
      already,
      cancelBtn } = this;

    return (
      <div
        id='framebind-dialog'
        // style={bindframedis ? { display: 'none' } : {}}
      >
        <div className='framebind-wrapper'>
          <div className='dialog-header'>
            <h3>{t('BindingFrame')}</h3>
            {!noframes && <span onClick={this.handlecancel}>
              <Icon type="close" />
            </span>}
          </div>
          <div className='dialog-boby'>
            {visible ? <Loading
              disabled={disabled}
              bindloading={bindloading}
              bindfailed={bindfailed}
              offline={offline}
              logout={this.logout}
              inputReg={this.inputReg}
              handlecancel={this.handlecancel}
              handleconfirm={this.handleconfirm1}
              noframes={noframes}
              cancelBtn={cancelBtn}
              noframesHandlecancel={this.noframesHandlecancel}
            /> : <Success
                disabled={disabled}
                nameinput={this.nameinput}
                already={already}
                bindloading={bindloading}
                cancelBtn={cancelBtn}
                noframes={noframes}
                defaultplaylistId={this.defaultplaylistId}
                select_name={select_name}
                handleOk={this.handleOk}
                handlecancel={this.handlecancel}
                handleconfirm={this.handleconfirm2}
              />}
          </div>
        </div>
      </div>
    );
  }
}

export default Framebinddialog;
