import { observable, action } from 'mobx';

class recycle {
  @observable selectItems = new Set();
  @observable seleceds = new Set(); //选择列表

  //图片
  @action selectList = (e, id) => {
    e.stopPropagation();
    if (this.selectItems.has(id)) {
      this.selectItems.delete(id);
    } else {
      this.selectItems.add(id);
    }
  }

  @action checkAll = data => {
    const arr = data.filter(el => el.id);
    if (this.selectItems.size !== arr.length) {
      arr.forEach(item => {
        if (item.id) {
          this.selectItems.add(item.id);
        }
      });
    } else {
      this.selectItems = new Set();
    }
  }

  @action cancelAll=()=>{
    this.selectItems = new Set();
  }

  @action invert = data => {
    const arr = data.filter(el => el.id);
    arr.forEach(item => {
      if (this.selectItems.has(item.id)) {
        this.selectItems.delete(item.id)
      } else {
        this.selectItems.add(item.id);
      }
    })

  }

  @action delete = () => {
    this.selectItems = new Set();
  }
  //end


  //列表
  @action ToPlaceCheckAll = (data) => {
    // const arr = data.filter(el => el.id);
    if (this.seleceds.size !== data.length) {
      data.forEach(item => {
        this.seleceds.add(item.id)
      })
    } else {
      this.seleceds = new Set();
    }
  }

  @action goPlaylistdetails = (id) => {
    if (this.seleceds.has(id)) {
      this.seleceds.delete(id);
    } else {
      this.seleceds.add(id);
    }
  }

  @action ToPlaceInvert = (arr) => {
    const { seleceds } = this;
    // const arr = data.filter(el => el.id);
    arr.forEach(el => {
      if (seleceds.has(el.id)) {
        seleceds.delete(el.id);
      } else {
        seleceds.add(el.id);
      }
    });
  }

  @action ToPlaceDelete = () => {
    this.seleceds = new Set();
  }
  //end

  @action reset = () => {
    this.selectItems = new Set();
  }
}

export default new recycle();